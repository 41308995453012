import React from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NavLink = ({ to, children, miniMode = false, ...restProps }) => (
  <Box
    sx={{
      display: 'inline-block',
      '& > a': {
        display: 'flex',
        alignItems: 'center',
        px: 2,
        position: 'relative',
        textDecoration: 'none',
        '&:hover': {
          color: 'primary.main',
          '&::before': {
            borderColor: 'primary.main',
            ...(miniMode ? { borderLeft: '4px solid' } : { borderTop: '4px solid' }),
          },
        },
      },
      '& > a::before': {
        content: '""',
        height: 1,
        width: 1,
        backgroundColor: 'transparent',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'all 0.05s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s',
        ...(miniMode ? {} : { top: '-16px' }),
      },
    }}
  >
    <BaseNavLink to={to} {...restProps}>
      {({ isActive }) => (
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            display: 'inline-block',
            height: 32,
            lineHeight: '32px',
            letterSpacing: '4px',
            color: isActive ? 'primary.main' : 'text.primary',
            '&:hover': { color: 'primary.main' },
          }}
        >
          {children}
        </Typography>
      )}
    </BaseNavLink>
  </Box>
);

export default NavLink;
