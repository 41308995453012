import isAfter from 'date-fns/isAfter';

const baseUrl = 'rest/';
const serviceName = 'client_CompetitionService';
export const types = ['Competition'];

export default (builder) => ({
  // 获取竞赛列表
  getCompetitions: builder.query({
    query: ({ data, signal }) => ({
      // baseURL: '',
      // url: '/competitions/list.json',
      url: `${baseUrl}services/${serviceName}/search`,
      method: 'post',
      data: { request: data },
      signal,
      isPublic: true,
    }),
    // transformResponse: (response) => {
    //   const now = new Date();
    //   const avaliableList =
    //     response?.data?.list?.filter((item) => !item?.startDate || isAfter(now, item?.startDate)) || [];
    //   return {
    //     list: avaliableList,
    //     total: avaliableList?.length,
    //   };
    // },
  }),

  // 获取竞赛详情
  getCompetition: builder.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/query`,
      method: 'post',
      data: { id },
      isPublic: true,
    }),
    // transformResponse: (response) => response?.data,
  }),
});
