import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import { commonActions } from 'store/commonSlice';

const Thememode = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.common.theme);

  const handleToggle = () => {
    dispatch(commonActions.toggleTheme());
  };

  return (
    <Tooltip title="点击切换主题色">
      <IconButton size="large" aria-label="style mode" onClick={handleToggle} color="inherit">
        {theme === 'dark' ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default Thememode;
