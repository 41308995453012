const settings = {
  account: '账户',
  email: '邮箱',
  username: '用户名',
  changeEmail: '修改邮箱',
  deleteAccount: '删除账户',
  password: '密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  changePassword: '修改密码',
  tooltips: {
    deleteAccount: {
      title: '您确定执行此操作？',
      message: '我们将立即删除您的所有信息',
    },
  },
};

export default settings;
