import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SchoolIcon from '@mui/icons-material/School';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IconVip, IconBuildingWarehouse } from '@tabler/icons';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import UserImg from './components/UserImg';
import { authActions } from 'store/authSlice';
import api from 'services/api';
import AuthModal from './AuthModal';
import { vipTypeMap, userTypeMap, enterpriseUserType } from 'common/constans';

export default function UserPannel() {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [revokeToken] = api.useRevokeTokenMutation();

  const authMode = searchParams.get('authMode') || null;
  useEffect(() => {
    if (token && authMode) {
      setSearchParams({});
    }
  }, [token, setSearchParams, authMode]);

  const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 11,
    fontWeight: 500,
    border: '1px solid',
    lineHeight: '16px',
    backgroundColor: 'rgba(228,232,251)',
    borderColor: 'rgba(168,184,255)',
    color: 'rgba(91,119,246)',
    '&:hover': {
      boxShadow: '1px 1px 20px 0 rgb(168,184,255 / 20%)',
    },
  });
  const handleLogin = () => {
    setSearchParams({ authMode: 'login' });
  };

  const handleSignup = () => {
    setSearchParams({ authMode: 'signup' });
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (link) => {
    navigate(link);
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    revokeToken();
    dispatch(authActions.clearCredentials());
  };

  if (token) {
    const userRender = (
      <ListItemText sx={{ pl: 2, mb: 1 }}>
        <Box sx={{ display: 'flex' }}>
          <UserImg avatar={user?.avatar} name={user?.email} />
          <Box sx={{ ml: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }} maxWidth={200} noWrap>
              {user?.nickname}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 400, color: 'rgba(75, 85, 96, 0.7)' }} maxWidth={200} noWrap>
              {user?.email}
            </Typography>
            <Box>
              {user?.userType === userTypeMap.ENTERPRISE &&
                user?.enterprise?.map((ent) => (
                  <BootstrapButton
                    // variant="outlined"
                    key={ent?.enterpriseId}
                    sx={{
                      py: 0.2,
                      mt: 1,
                      mr: 1,
                      borderRadius: '20px',
                    }}
                    size="small"
                    {...(ent?.vipType === vipTypeMap.VIP && { endIcon: <IconVip size={20} /> })}
                    {...(ent?.identity === enterpriseUserType.ADMIN || ent?.identity === enterpriseUserType.SUPER
                      ? {
                          onClick: () => handleRedirect(`settings/enterprise/${ent?.enterpriseId}`),
                        }
                      : '')}
                  >
                    {ent?.name}
                  </BootstrapButton>
                ))}
            </Box>
          </Box>
        </Box>
      </ListItemText>
    );

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          sx={{ display: { xs: 'none', md: 'block' } }}
          onClick={() => handleRedirect(`my`)}
        >
          我的学习
        </Button>
        <Box display={{ xs: 'none', md: 'block' }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpen}
            color="inherit"
            sx={{ p: 0.5 }}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                user?.userType === userTypeMap.ENTERPRISE && (
                  <Avatar sx={{ bgcolor: 'primary.main', width: 16, height: 16, fontSize: 12 }}>企</Avatar>
                )
              }
            >
              {user?.avatar ? <Avatar src={user?.avatar} sx={{ width: 36, height: 36 }} /> : <AccountCircle />}
            </Badge>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: { minWidth: 230, maxWidth: 240 },
            }}
          >
            {userRender}
            <Divider />
            <MenuItem onClick={() => handleRedirect('settings')}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>账号信息</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleRedirect('my/courses')}>
              <ListItemIcon>
                <SchoolIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>我的学习</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleRedirect(`user/${user?.id}`)}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>个人资料</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout} sx={{ textAlign: 'center' }}>
              {/* <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon> */}
              <ListItemText sx={{ color: 'red' }}>退出登录</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          <MenuList>
            <Divider />
            {userRender}
            <MenuItem onClick={() => handleRedirect(`my`)}>
              <ListItemIcon>
                <ContactMailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>我的学习</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleRedirect('settings')}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>账号</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleRedirect(`user/${user?.id}`)}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>个人资料</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText>退出</ListItemText>
            </MenuItem>
          </MenuList>
        </Box>
      </>
    );
  }

  return (
    <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} p={{ xs: 2, md: 0 }}>
      <Button variant="text" onClick={handleLogin}>
        登录
      </Button>
      <Button variant="outlined" onClick={handleSignup}>
        立即注册
      </Button>
      <AuthModal />
    </Stack>
  );
}
