import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';

const FormChip = ({ name, label, inputLabelProps, chipProps, options, ...restProps }) => {
  const [, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  const { value } = meta;
  const { setValue } = helpers;

  const handleClick = (newVal) => {
    if (value.includes(newVal)) {
      const newValues = value.filter((v) => v !== newVal);
      setValue(newValues);
    } else {
      setValue([...value, newVal]);
    }
  };
  return (
    <FormControl {...restProps}>
      {/* {label && (
        <InputLabel shrink error={!!error} {...inputLabelProps}>
          {label}
        </InputLabel>
      )} */}
      <Box display="flex" flexWrap="wrap" mt={0.6}>
        {options.map(({ value: val, text }) => {
          const colorProps = value?.includes(val) ? { color: 'single' } : {};
          return (
            <Chip
              key={val}
              label={text}
              variant="outlined"
              // size="small"
              sx={{ mr: 1, borderRadius: '4px', fontSize: '10px', height: '39.97px' }}
              {...colorProps}
              onClick={() => handleClick(val)}
              {...chipProps}
            />
          );
        })}
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormChip;
