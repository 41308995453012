import { configureStore } from '@reduxjs/toolkit';

import api from 'services/api';
import commonReducer from './commonSlice';
import authReducer from './authSlice';
import membersReducer from './members';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    common: commonReducer,
    auth: authReducer,
    members: membersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // 处理非序列化数据校验
    }).concat(api.middleware),
});
