import React from 'react';
import Link from '@mui/material/Link';
import Text from 'components/Text';

const hrefMap = {
  'en-us': '/user-agreement_en.html',
  'zh-cn': '/user-agreement_zh.html',
};

const UserAgreement = ({ language, ...restProps }) => (
  <Link target="_blank" rel="noopener noreferrer" href={hrefMap[language]} {...restProps}>
    <Text tid="common.footer.EULA" />
  </Link>
);

export default UserAgreement;
