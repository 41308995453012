import React from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function RequireAuth({ redirect, element, roles = [] }) {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const params = useParams();

  if (token && roles.length === 0) {
    if (React.isValidElement(element)) {
      return element;
    }
    return <Outlet />;
  }

  if (roles.length && roles.includes(user?.entAdminType)) {
    if (React.isValidElement(element)) {
      return element;
    }
    return <Outlet />;
  }

  if (typeof redirect === 'function') {
    return <Navigate to={redirect(params)} replace />;
  }
  return <Navigate to={redirect} replace />;
}
