import React, { useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function SelectionHeader({ columns, column, columnIndex, headerIndex, container }) {
  const filteredData = useMemo(() => {
    const { data, getCheckboxProps } = column;
    if (typeof getCheckboxProps === 'function') {
      return data.filter((rowData) => {
        const { disabled } = getCheckboxProps(rowData);
        return !disabled;
      });
    }
    return data;
  }, [column]);

  const handleSelectAllChange = ({ target: { checked } }) => {
    const { rowKey, onChange } = column;
    let selectedRowKeys = [];
    let selectedRows = [];
    if (checked) {
      selectedRowKeys = [...filteredData.map((item) => item[rowKey])];
      selectedRows = [...filteredData];
    }

    onChange({ selectedRowKeys, selectedRows });
  };

  const { selectedRowKeys, type } = column;

  if (type === 'radio') {
    return null;
  }
  return (
    <Checkbox
      disabled={filteredData?.length === 0}
      indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length !== filteredData?.length}
      checked={selectedRowKeys?.length > 0 && selectedRowKeys?.length === filteredData?.length}
      onChange={handleSelectAllChange}
    />
  );
}
