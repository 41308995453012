import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedRowKeys: [],
  tabValue: '1',
  expandeds: 0,
  organizePermission: [],
  organizeDelect: [],
  caseTabIds: [],
  caseTabs: [],
  caseMove: [],
};

export const memberSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setSelectedRows: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setexpandeds: (state, action) => {
      state.expandeds = action.payload;
    },
    // 初始化保存下拉框数据
    setorganizePermission: (state, action) => {
      state.organizePermission = action.payload;
    },
    // 保存下拉多选框数据（进阶tab）
    setcaseTabs: (state, action) => {
      state.caseTabs = action.payload;
    },
    // 进阶TAB全选
    setMoveCaseTabs: (state, action) => {
      if (action.payload.move) {
        state.caseTabs = state.caseTabs.filter((item) => item.parentid !== action.payload.parentid);
        action.payload.options.map((item) => {
          const list = {
            id: item.value,
            tabName: item.text,
            parentid: action.payload.parentid,
          };
          state.caseTabs.push(list);
          return '';
        });
        state.caseMove.push({ text: '进阶TAB', value: 'move', parentid: action.payload.parentid });
      } else {
        state.caseMove = state.caseMove.filter((item) => item.parentid !== action.payload.parentid);
        state.caseTabs = state.caseTabs.filter((item) => item.parentid !== action.payload.parentid);
      }
    },
    setMoveCase: (state, action) => {
      if (action.payload.move) {
        state.caseMove.push({ text: '进阶TAB', value: 'move', parentid: action.payload.parentid });
      } else {
        state.caseMove = state.caseMove.filter((item) => item.parentid !== action.payload.parentid);
      }
    },

    // 下拉配置之后保存配置内容
    setorganizePermissions: (state, action) => {
      if (state.organizePermission.length > 0) {
        // 避免重复添加
        state.organizePermission.map((item) => {
          if (item?.organizationId === action.payload?.organizationId) {
            item.organizePermissions = action.payload?.organizePermissions;
          } else {
            state.organizePermission.push(action.payload);
          }
          state.organizePermission = state.organizePermission.filter((item) => item.organizePermissions !== 'NONE');

          return '';
        });
      } else {
        state.organizePermission.push(action.payload);
        state.organizePermission = state.organizePermission.filter((item) => item.organizePermissions !== 'NONE');
      }
    },

    setorganizePermissiondel: (state, action) => {
      state.organizePermission = state.organizePermission.filter(
        (item) => item.organizationId !== action.payload.organizationId
      );
      state.caseTabs = state.caseTabs.filter((item) => item.parentid !== action.payload.organizationId);
    },

    setcasePermissiondel: (state, action) => {
      let flag = null;
      state.caseTabs.map((item) => {
        if (item.id === action.payload.list[0]?.value && item.parentid === action.payload.parentid) {
          // eslint-disable-next-line no-return-assign
          return (flag = true);
        }
        return '';
      });
      if (flag) {
        const list = [];
        state.caseTabs.map((item) => {
          if (item.parentid === action.payload.parentid && item.id === action.payload.list[0]?.value) {
            console.log();
          } else {
            list.push(item);
          }
          return '';
        });
        state.caseTabs = list;
      } else {
        const list = {
          id: action.payload.list[0]?.value,
          tabName: action.payload.list[0].text,
          parentid: action.payload.parentid,
        };
        state.caseTabs.push(list);
      }
    },
    setcaseTabsDelect: (state, action) => {
      state.caseTabs = state.caseTabs.filter((item) => item.parentid !== action.payload);
    },
    setorganizeDelect: (state, action) => {
      state.organizeDelect = [];
      state.organizeDelect.push(action.payload);
    },
  },
});

export const memberActions = {
  ...memberSlice.actions,
};

export default memberSlice.reducer;
