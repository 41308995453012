import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const FormTextField = ({ name, helperText, onBlur, ...restProps }) => {
  const [{ onBlur: onFieldBlur, ...field }, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  return (
    <TextField
      {...field}
      error={Boolean(error)}
      helperText={error || helperText}
      onBlur={(e) => {
        onFieldBlur(e);
        if (typeof onBlur === 'function') {
          onBlur(e, field.value, error, helpers?.setError);
        }
      }}
      {...restProps}
    />
  );
};

export default FormTextField;
