import React from 'react';
import Avatar from '@mui/material/Avatar';

import { headPortraitColor } from 'common/constans';

const UserImg = ({ avatar, name = '' }) => {
  const num = Math.round(Math.random() * 10);
  if (avatar) {
    return <img src={avatar} alt="" loading="lazy" style={{ width: '42px', height: '42px', borderRadius: '50%' }} />;
  }

  return (
    <Avatar
      sx={{
        bgcolor: headPortraitColor[num]?.fillColor,
        border: 1,
        borderColor: headPortraitColor[num]?.borderColor,
        color: headPortraitColor[num]?.fontColor,
      }}
    >
      {name && name.charAt(0)}
    </Avatar>
  );
};

export default UserImg;
