export const types = ['Case', 'Private_Case', 'Fork_Case'];

const baseUrl = 'rest/';
const serviceName = 'client_CaseService';

export default (build) => ({
  // 查询课程分类
  getCaseCategories: build.query({
    query: ({ signal }) => ({
      url: `${baseUrl}services/client_CategoryService/queryCase`,
      method: 'post',
      signal,
    }),
  }),
  // 查询案例列表(Public)
  getCases: build.query({
    query: ({ data, signal, authorized }) => {
      if (authorized) {
        return {
          url: `${baseUrl}services/${serviceName}/searchEnt`,
          method: 'post',
          data: { request: data },
        };
      }
      return {
        url: `${baseUrl}services/${serviceName}/search`,
        method: 'post',
        data: { request: data },
        signal,
        isPublic: true,
      };
    },
    providesTags: (result) =>
      result?.data.length
        ? [...result.data.map(({ id }) => ({ type: 'Case', id })), { type: 'Case', id: 'LIST' }]
        : [{ type: 'Case', id: 'LIST' }],
  }),

  getCase: build.query({
    query: ({ id, token }) => {
      if (token) {
        return {
          url: `${baseUrl}services/${serviceName}/queryChoose`,
          method: 'post',
          data: { id },
        };
      }
      return {
        url: `${baseUrl}services/${serviceName}/query`,
        method: 'post',
        data: { id },
        isPublic: true,
      };
    },
    providesTags: (result, error, { token, id }) => [{ type: token ? 'Private_Case' : 'Case', id }],
  }),

  // 获取案例基本信息以及详情
  getCaseDetail: build.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/queryDetail`,
      method: 'post',
      data: { id },
    }),
  }),

  // 获取案例附件中心列表
  ShareFileService: build.query({
    query: (data) => ({
      url: `${baseUrl}services/client_ShareFileService/caseSearch`,
      method: 'post',
      data: {
        request: data,
      },
    }),
  }),

  // 选案例(Private)
  registerCase: build.mutation({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/choose`,
      method: 'post',
      data: { id },
    }),
    invalidatesTags: (result, error, id) => [{ type: 'Private_Case', id }],
  }),

  // 查询已选的课程列表(Private)
  getPrivateCases: build.query({
    query: ({ data, signal }) => ({
      url: `${baseUrl}services/${serviceName}/chooseRecord`,
      method: 'post',
      data: { request: data },
      signal,
    }),
    providesTags: (result) =>
      result?.data.length
        ? [...result.data.map(({ id }) => ({ type: 'Private_Case', id })), { type: 'Private_Case', id: 'LIST' }]
        : [{ type: 'Private_Case', id: 'LIST' }],
  }),

  // 获取案例基本信息以及详情
  getNotebookHtml: build.query({
    query: (data) => ({
      url: `${baseUrl}download/html`,
      method: 'post',
      data,
    }),
    responseType: 'blob',
  }),

  // 更加案例类型 以及用户会员属性显示fork按钮
  showFork: build.query({
    query: (caseId) => ({
      url: `${baseUrl}services/${serviceName}/forkButton`,
      method: 'post',
      data: { caseId },
    }),
  }),

  // fork代码
  forkCode: build.mutation({
    query: (caseId) => ({
      url: `${baseUrl}services/${serviceName}/forks`,
      method: 'post',
      data: { caseId },
    }),
    invalidatesTags: () => [{ type: 'Fork_Case', id: 'LIST' }],
  }),

  // 获取fork代码版本列表
  getCodeVersions: build.query({
    query: (caseId) => ({
      url: `${baseUrl}services/${serviceName}/findVersions`,
      method: 'post',
      data: { caseId },
    }),
    providesTags: () => [{ type: 'Fork_Case', id: 'LIST' }],
  }),

  deleteVersions: build.mutation({
    query: ({ caseId, version }) => ({
      url: `${baseUrl}services/${serviceName}/deleteVersions`,
      method: 'post',
      data: { caseId, version },
    }),
    invalidatesTags: () => [{ type: 'Fork_Case', id: 'LIST' }],
  }),

  // 新建课程
  continueCaseStudy: build.mutation({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/queryJump`,
      method: 'post',
      data: { id },
    }),
    invalidatesTags: () => [{ type: 'Private_Course', id: 'LIST' }], // 需要更新我的课程列表
  }),
});
