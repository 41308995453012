import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MuiTableCell = ({ cellData, column }) => {
  if (column?.ellipsis) {
    return <Box>{cellData}</Box>;
  }
  const { cellProps = {} } = column;

  if (typeof cellData === 'string') {
    return (
      <Typography noWrap fontSize={14} {...cellProps}>
        {cellData}
      </Typography>
    );
  }
  return (
    <Box component="div" {...cellProps}>
      {cellData}
    </Box>
  );
};

export default MuiTableCell;
