import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { external, href, children, ...restProps } = props;
  if (external) {
    return (
      <a ref={ref} href={href} {...restProps}>
        {children}
      </a>
    );
  }
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink ref={ref} to={href} {...restProps}>
      {children}
    </RouterLink>
  );
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

export default LinkBehavior;
