import React from 'react';
import { Navigate } from 'react-router-dom';

import devRoutes from 'features/development/routes';
import RequireAuth from 'features/authentication/RequireAuth';
import Members from 'features/settings/enterprise/organization/OrganizationMembers';

const NotFound = React.lazy(() => import('components/exception/NotFound'));

const Home = React.lazy(() => import('features/home/Home'));

// User
const User = React.lazy(() => import('features/authentication/User'));

const Setting = React.lazy(() => import('features/settings/Setting'));
const SettingAccount = React.lazy(() => import('features/settings/Account'));
const SettingProfile = React.lazy(() => import('features/settings/Profile'));
const VipCenter = React.lazy(() => import('features/settings/VipCenter'));

// Course
const CourseList = React.lazy(() => import('features/course/public/CourseList'));
const CoursePublicHome = React.lazy(() => import('features/course/public/Home'));
const CoursePrivateHome = React.lazy(() => import('features/course/private/Home'));
const CourseWeek = React.lazy(() => import('features/course/private/CourseWeek'));
const CourseInfo = React.lazy(() => import('features/course/private/Info'));
const InstructorInfo = React.lazy(() => import('features/course/private/InstructorInfo'));
const CourseDetail = React.lazy(() => import('features/course/private/CourseDetail'));
const CoursePrivateList = React.lazy(() => import('features/course/private/CourseList'));
const Attachment = React.lazy(() => import('features/course/private/Attachment'));

// Case
const CaseList = React.lazy(() => import('features/case/public/CaseList'));
const CasePrivateList = React.lazy(() => import('features/case/private/CaseList'));
const CasePublicHome = React.lazy(() => import('features/case/public/Home'));
const CasePrivateHome = React.lazy(() => import('features/case/private/Home'));

// Competition
const CompetitionList = React.lazy(() => import('features/competition/CompetitionList'));
const CompetitionDetail = React.lazy(() => import('features/competition/CompetitionDetail'));

// Lecture
const LectureList = React.lazy(() => import('features/lecture/LectureList'));
const LectureDetail = React.lazy(() => import('features/lecture/LectureDetail'));

// Enterprise
const Member = React.lazy(() => import('features/settings/enterprise/member/Member'));
const AddMember = React.lazy(() => import('features/settings/enterprise/member/components/AddMember'));
// administration
const Administration = React.lazy(() => import('features/settings/enterprise/administration/Administration'));
// OrganizationChart
const OrganizationChart = React.lazy(() => import('features/settings/enterprise/organization/OrganizationChart'));
// My
const My = React.lazy(() => import('features/my/My'));

// 开发环境使用
const isDevelopment = process.env.NODE_ENV === 'development';

const routes = [
  { path: '', index: true, element: <Home /> },
  {
    path: 'courses',
    children: [
      {
        index: true,
        element: <CourseList />,
      },
      {
        path: 'home/:id',
        element: <CoursePublicHome />,
      },
      {
        path: ':id',
        element: <RequireAuth redirect={({ id }) => `/courses/home/${id}`} />,
        children: [
          {
            path: 'home',
            element: <CoursePrivateHome />,
            children: [
              {
                path: 'week',
                children: [
                  {
                    path: ':weekId',
                    element: <CourseWeek />,
                  },
                ],
              },
              {
                path: 'info',
                element: <CourseInfo />,
              },
              {
                path: 'instructor',
                element: <InstructorInfo />,
              },
              {
                path: 'attachment',
                element: <Attachment />,
              },
            ],
          },
          {
            path: ':detailType/:detailId',
            element: <CourseDetail />,
          },
        ],
      },
    ],
  },
  {
    path: 'cases',
    children: [
      {
        index: true,
        element: <CaseList />,
      },
      {
        path: 'home/:id',
        element: <CasePublicHome />,
      },
      {
        path: ':id',
        element: <RequireAuth redirect={({ id }) => `/cases/home/${id}`} />,
        children: [
          {
            path: '',
            index: true,
            element: <CasePrivateHome />,
          },
        ],
      },
    ],
  },
  {
    path: 'competitions',
    children: [
      {
        index: true,
        element: <CompetitionList />,
      },
      {
        path: ':id',
        element: <CompetitionDetail />,
      },
    ],
  },
  {
    path: 'lecture',
    children: [
      {
        index: true,
        element: <LectureList />,
      },
      {
        path: ':id',
        element: <LectureDetail />,
      },
    ],
  },
  {
    path: 'my',
    element: <RequireAuth redirect="/" element={<My />} />,
    children: [
      { index: true, element: <Navigate to="courses" /> },
      {
        path: 'courses',
        element: <CoursePrivateList />,
      },
      {
        path: 'cases',
        element: <CasePrivateList />,
      },
    ],
  },
  { path: 'user/:id', element: <RequireAuth redirect="/" element={<User />} /> },
  {
    path: 'settings',
    element: <RequireAuth redirect="/" element={<Setting />} />,
    children: [
      { index: true, element: <Navigate to="profile" /> },
      { path: 'account', element: <SettingAccount /> },
      { path: 'profile', element: <SettingProfile /> },
      {
        path: 'enterprise',
        children: [
          {
            path: ':id',
            element: <RequireAuth roles={['ADMIN']} element={<Member />} redirect="/settings" />,
          },
          {
            path: ':id/add',
            element: <RequireAuth roles={['ADMIN']} element={<AddMember />} redirect="/settings" />,
          },
          {
            path: ':id/members/:id',
            element: <Members roles={['ADMIN']} element={<AddMember />} redirect="/settings" />,
          },
        ],
      },
      {
        path: 'administration/:id',
        element: <Administration />,
      },
      {
        path: 'OrganizationChart',
        // element: <OrganizationChart />,
        children: [
          {
            path: ':id',
            element: <RequireAuth roles={['ADMIN']} element={<OrganizationChart />} />,
          },
          {
            path: ':id/members/:id',
            element: <Members roles={['ADMIN']} element={<AddMember />} />,
          },
        ],
      },

      // { path: 'vip', element: <VipCenter /> },
    ],
  },
  { path: 'notifications', element: <NotFound /> },
  { path: 'recommendations', element: <NotFound /> },
  ...(isDevelopment ? devRoutes : []),
  { path: '*', element: <NotFound /> },
];

export default routes;
