import { AutoResizer } from 'react-base-table';
import { styled } from '@mui/material/styles';

const AutoResizerStyle = styled(AutoResizer)(({ theme }) => ({
  '& .BaseTable': {
    fontSize: 14,
    boxShadow: 'none',
  },
  '& .BaseTable__table-frozen-left': {
    boxShadow: '2px 0 3px 0 #e7e7e7',
  },
  '& .BaseTable__table-frozen-right': {
    boxShadow: '-2px 0 4px 0 #e7e7e7',
  },
  '& .BaseTable__table-main': {
    outline: 'none',
    // outline: '1px solid #e7e7e7',
  },
  '& .BaseTable__header-row': {
    background: '#fff ',
    color: '#4B5560',
    // borderTop: '1px solid #e7e7e7',
  },
  '& .BaseTable__row:hover': {
    background: '#f8f8f8',
  },
  '& .BaseTable__table-frozen-left .BaseTable__header-cell:first-of-type': {
    paddingLeft: 7.5,
  },
  '& .BaseTable__table-frozen-left .BaseTable__row-cell:first-of-type': {
    paddingLeft: 7.5,
  },
}));

export default AutoResizerStyle;
