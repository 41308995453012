import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import Text from 'components/Text';

import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

const FormSendCode = ({ name, helperText, sendCode, sendText, InputProps, ...restProps }) => {
  const codeTimer = useRef();
  const [count, setCount] = useState(0);
  const [field, meta] = useField(name);

  const error = meta.touched && meta.error;

  useEffect(() => {
    codeTimer.current = setTimeout(() => {
      if (count > 0) {
        setCount((c) => c - 1);
      }
    }, 1000);
    return () => {
      if (codeTimer.current) {
        clearTimeout(codeTimer.current);
      }
    };
  }, [count]);

  const renderCount = () =>
    count > 0 ? (
      <span>
        <Text tid="common.form.resend" />
        {`${count}s`}
      </span>
    ) : (
      <Text tid="common.form.sendCode" />
    );

  return (
    <TextField
      {...field}
      error={Boolean(error)}
      helperText={error || helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={() => sendCode(setCount)} disabled={count > 0}>
              {typeof sendText === 'function' ? sendText(count) : renderCount()}
            </Button>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...restProps}
    />
  );
};

export default FormSendCode;
