import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';

import Search from '../components/Search';
import TableContainer from 'components/table/TableContainer';
import ProTable from 'components/table/ProTable';
import ResponsiveDialog from 'components/dialog/ResponsiveDialog';
import ClosableDialogTitle from 'components/dialog/ClosableDialogTitle';
import api from 'services/api';

const OrganizationAddMem = ({ title = '', oId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [memberNew, { isLoading: isLoadingNew }] = api.useMemberNewMutation();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { id } = useParams();
  const { data = { total: 0, data: [] }, isLoading } = api.useMemberListQuery(
    {
      enterpriseId: id,
      organizationId: oId,
      deactivate: false,
      search,
      limit: pageSize,
      offset: page * pageSize,
    },
    {
      skip: !id || !oId || !open,
    }
  );
  const handleClose = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleComfirm = () => {
    if (selectedRowKeys.length > 0) {
      memberNew({
        organizationId: oId,
        userIds: selectedRowKeys,
        enterpriseId: id,
      })
        .unwrap()
        .then(() => {
          setSelectedRowKeys([]);
          setSelectedRows([]);
          enqueueSnackbar('新增成功!', { variant: 'success' });
          setOpen(false);
        })
        .catch((error) => {
          enqueueSnackbar(`新增失败：${error.message}`, { variant: 'error' });
        });
    }
  };

  const handleSearch = ({ search = '' }) => {
    setSearch(search.trim());
    setPage(0);
  };

  // 分页
  const handlePerPageChange = (event) => {
    setPageSize(event.target.value);
  };
  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const columns = [
    { key: 'nickName', dataKey: 'nickName', title: '昵称', width: 160 },
    { key: 'email', title: '邮箱', dataKey: 'email', width: 280 },
  ];

  return (
    <>
      <Tooltip title={title}>
        <IconButton size="small" variant="outlined" onClick={handleOpen}>
          <AddCircleOutlineIcon size="small" />
        </IconButton>
      </Tooltip>

      <ResponsiveDialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <ClosableDialogTitle onClose={handleClose}>{title}</ClosableDialogTitle>
        <Divider />
        <DialogContent sx={{ minHeight: 300 }}>
          <Box>
            <Toolbar>
              <Search onSubmit={handleSearch} />
              <Box flexGrow={1} />
            </Toolbar>
            <TableContainer isLoading={isLoading}>
              <ProTable
                rowKey="id"
                columns={columns}
                data={data?.data}
                rowSelection={{
                  selectedRowKeys,
                  selectedRows,
                  onChange: ({ selectedRowKeys, selectedRows }) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setSelectedRows(selectedRows);
                  },
                }}
              />
              <Box justifyContent="flex-end" display="flex" p={2}>
                <TablePagination
                  component="div"
                  count={data?.total}
                  page={page}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handlePerPageChange}
                />
              </Box>
            </TableContainer>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box mr={5}>已选{selectedRowKeys.length}人</Box>
          <LoadingButton variant="contained" onClick={handleComfirm} loading={isLoadingNew || isLoading}>
            确定
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            取消
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default OrganizationAddMem;
