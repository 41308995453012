import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const FormPassword = ({ name, helperText, InputProps: inputProps, ...restProps }) => {
  const [field, meta] = useField(name);
  const [show, setShow] = React.useState(false);
  const error = meta.touched && meta.error;

  const showPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      error={Boolean(error)}
      helperText={error || helperText}
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={showPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
        ...inputProps,
      }}
      {...restProps}
    />
  );
};

export default FormPassword;
