import React from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

import FormTextField from 'components/form/FormTextField';

const Search = ({ onSubmit, label = '昵称/邮箱', ...rest }) => (
  <Box {...rest}>
    <Formik initialValues={{ search: '' }} onSubmit={onSubmit}>
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <FormTextField label={label} fullWidth name="search" margin="none" size="small" />
          </Grid>
          <Grid item xs={3}>
            <IconButton color="primary" type="submit">
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  </Box>
);

export default Search;
