import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import logo from 'assets/images/title-logo.png';
import rclogo from 'assets/images/rc-title-logo.png';

import ElevationScroll from 'components/ElevationScroll';
import NavLink from './NavLink';

export default function GlobalAppBar({ changeColorOnScroll, rightLinks }) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      // document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      // document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
    } else {
      // document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      // document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
    }
  };

  useEffect(() => {
    window.removeEventListener('scroll', headerColorChange);
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  }, [changeColorOnScroll]);

  return (
    <>
      <ElevationScroll>
        <AppBar color="inherit" sx={{ boxShadow: 1 }}>
          <Toolbar>
            <Box component={Link} to="/">
              {process.env.REACT_APP_ENV === 'test' ? (
                <Box component="img" src={rclogo} height={{ xs: 30, sm: 36 }} />
              ) : (
                <Box component="img" src={logo} height={{ xs: 30, sm: 36 }} />
              )}
            </Box>
            {/* <Typography
              component={Link}
              to="/"
              variant="h6"
              noWrap
              sx={{ textDecoration: 'none', color: '#1abac5', fontWeight: 600, display: { xs: 'none', sm: 'block' } }}
            >
              CORIDM
            </Typography> */}
            <Box sx={{ px: 4, display: { xs: 'none', md: 'block' } }}>
              <NavLink to="/">首页</NavLink>
              <NavLink to="/courses">课程</NavLink>
              <NavLink to="/cases">案例</NavLink>
              <NavLink to="/competitions">竞赛</NavLink>
              <NavLink to="/lecture">讲座</NavLink>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stack direction="row" spacing={2} alignItems="center">
                {rightLinks}
                {/* {theme} */}
              </Stack>
            </Box>
            <Hidden mdUp>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Hidden mdUp implementation="js">
        <Drawer variant="temporary" anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
          <Box minWidth={200} py={2}>
            <Stack spacing={2}>
              <NavLink miniMode to="/">
                首页
              </NavLink>
              <NavLink miniMode to="/courses">
                课程
              </NavLink>
              <NavLink miniMode to="/cases">
                案例
              </NavLink>
              <NavLink miniMode to="/competitions">
                竞赛
              </NavLink>
              <NavLink miniMode to="/lecture">
                讲座
              </NavLink>
            </Stack>
            {rightLinks}
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}

GlobalAppBar.defaultProp = {
  color: 'white',
};

GlobalAppBar.propTypes = {
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'dark'])
      .isRequired,
  }),
};
