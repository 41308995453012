import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';
import PropTypes, { element } from 'prop-types';
import { Box } from '@mui/material';

const TableContainer = ({
  children,
  // headRightRender,
  // headLeftRender,
  // onDelete,
  // onAdd,
  // onEdit,
  // disabled = true,
  isLoading = false,
  BottomHeight = 48,
  sx,
  ...rest
}) => (
  <Box
    sx={{
      height: '100%',
      minHeight: 400,
      ...sx,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      border: '1px solid #e7e7e7',
      borderRadius: '2px',
    }}
    {...rest}
  >
    {isLoading ? (
      <Box px={2}>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </Box>
    ) : (
      <Box sx={{ flex: 1, borderBottom: '1px solid #e7e7e7' }}>
        {children[0]}
        {/* <AutoSizer>
      {({ width, height }) => {
        const childrenWithProps = React.Children.map(children, (child, index) => {
          if (React.isValidElement(child) && index === 0) {
            return React.cloneElement(child, { width, height });
          }
          return null;
        });
        return childrenWithProps;
      }}
    </AutoSizer> */}
      </Box>
    )}

    {_.isArray(children) && (
      <Box
        sx={{
          px: 1,
          height: BottomHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        {children[1]}
      </Box>
    )}
  </Box>
);
TableContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  headRightRender: element,
  headLeftRender: element,
  sx: PropTypes.object,
};

export default TableContainer;
