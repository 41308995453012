import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import enLocale from 'date-fns/locale/en-US';
import zhLocale from 'date-fns/locale/zh-CN';
import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga4';

import AppLayout from 'components/layout/AppLayout';
import SuspenseFallback from 'components/spinner/SuspenseFallback';
import api from 'services/api';
import { authActions } from 'store/authSlice';
import lightTheme from 'theme/light';
import darkTheme from 'theme/dark';
import { LanguageProvider, useLocale } from './Language';
import routes from './routes';
import UserPannel from './authentication/UserPannel';
import ThemeMode from './ThemeMode';

// date-fns locale data
const localeMap = {
  'en-us': enLocale,
  'zh-cn': zhLocale,
};

// Material UI locale data
const MUILocaleMap = {
  'en-us': 'enUS',
  'zh-cn': 'zhCN',
};

// 主题
const themeMap = {
  light: lightTheme,
  dark: darkTheme,
};

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

ReactGA.initialize(TRACKING_ID);

function App() {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.common);
  const token = useSelector((state) => state.auth.token);
  const { locale } = useLocale();
  const app = useRoutes(routes);

  const { data, isLoading, isSuccess, error } = api.useGetUserInfoQuery(token, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        dispatch(authActions.setAccount(data?.data));
      } else {
        dispatch(authActions.resetAccount());
      }
    }
  }, [isSuccess, data, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(authActions.resetAccount());
    }
  }, [error, dispatch]);

  return (
    <ThemeProvider theme={themeMap[theme]}>
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[MUILocaleMap[locale] || 'enUS'])}>
        <CssBaseline enableColorScheme />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]}>
          <SnackbarProvider maxSnack={3}>
            <AppLayout loading={isLoading} menu={<UserPannel />} theme={<ThemeMode />}>
              <Suspense fallback={<SuspenseFallback />}>{app}</Suspense>
            </AppLayout>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ThemeProvider>
  );
}

const LanguageApp = () => (
  <LanguageProvider>
    <App />
  </LanguageProvider>
);

export default LanguageApp;
