import React, { useMemo } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ListItemText from '@mui/material/ListItemText';
import * as Yup from 'yup';
import FormTextField from 'components/form/FormTextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';

import ResponsiveDialog from 'components/dialog/ResponsiveDialog';
import ClosableDialogTitle from 'components/dialog/ClosableDialogTitle';
import api from 'services/api';

const validationSchema = Yup.object({
  organizeName: Yup.string().required('请输入组织名称'),
});
const PopUp = ({
  organizationId,
  text = '',
  type,
  title,
  lable,
  parentId = undefined,
  handleCloses,
  arr = {},
  level = 0,
  id = -1,
}) => {
  const context = useOutletContext();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const formikRef = React.useRef(null);
  const [organizationChartNew, { isLoading: isLoadingNew }] = api.useOrganizationChartNewMutation();
  const [deleteorganizationChart, { isLoading }] = api.useDeleteorganizationChartMutation();
  const [deleteMember, { isLoading: isLoadingDelete }] = api.useDeleteMemberMutation();
  const enterpriseId = useLocation().pathname.substring(useLocation().pathname.indexOf('OrganizationChart/') + 18);

  const { data } = api.useGetEnterpriseInfoByIdQuery(context?.lid, {
    skip: !context?.lid,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleComfirm = () => {
    if (title === '删除') {
      deleteorganizationChart(arr)
        .unwrap()
        .then(() => {
          enqueueSnackbar('删除成功!', { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(`删除失败：${error.message}`, { variant: 'error' });
        });
    } else if (title === '移除成员') {
      deleteMember({ enterpriseId, userId: arr?.id, organizationId })
        .unwrap()
        .then(() => {
          enqueueSnackbar('移除成功!', { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(`移除失败：${error.message}`, { variant: 'error' });
        });
    } else {
      formikRef.current.handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    organizationChartNew(e)
      .unwrap()
      .then(() => {
        setOpen(false);
        if (title !== '新增组织') {
          handleCloses();
        }
        enqueueSnackbar(`${title === '添加子部门' || title === '新增组织' ? '新增成功' : '修改成功'}`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${title === '添加子部门' ? '新增失败' : '修改失败'}:${error.response.details}`, {
          variant: 'error',
        });
      });
  };

  const btnDom = useMemo(() => {
    if (title === '新增组织') {
      return (
        <Tooltip title={title}>
          <IconButton size="small" onClick={handleOpen}>
            <AddCircleOutlineIcon size="small" />
          </IconButton>
        </Tooltip>
      );
    }
    if (title === '移除成员') {
      return (
        <Button size="small" onClick={handleOpen}>
          {title}
        </Button>
      );
    }
    return <ListItemText onClick={handleOpen}>{title}</ListItemText>;
  }, [title]);

  return (
    <>
      {btnDom}
      <ResponsiveDialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <ClosableDialogTitle onClose={handleClose}>{title}</ClosableDialogTitle>
        <Divider />
        <DialogContent>
          {type === 'delete' ? (
            <span>{text}</span>
          ) : (
            <Formik
              initialValues={{
                organizeName: arr?.organizeName || '',
                remark: arr?.remark || '',
                enterpriseId: data?.enterpriseId,
                level: title === '添加子部门' || title === '新增组织' ? level + 1 : level,
                parentId,
                status: 'ACTIVE',
                id,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              innerRef={formikRef}
              onSubmit={handleSubmit}
            >
              <Form>
                <FormTextField
                  inputProps={{
                    maxLength: 30,
                  }}
                  label={lable}
                  fullWidth
                  name="organizeName"
                  margin="normal"
                  size="small"
                  required
                />
                <FormTextField
                  inputProps={{
                    maxLength: 30,
                  }}
                  label="备注"
                  fullWidth
                  name="remark"
                  margin="normal"
                  size="small"
                />
              </Form>
            </Formik>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={handleComfirm}
            loading={isLoadingNew || isLoadingDelete || isLoading}
          >
            确定
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            取消
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default PopUp;
