const settings = {
  account: 'Account',
  email: 'email',
  username: 'Username',
  changeEmail: 'Change Email',
  deleteAccount: 'Delete Account',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  changePassword: 'Change Password',
  tooltips: {
    deleteAccount: {
      title: 'Are you sure you want to do this?',
      message: '',
    },
  },
};

export default settings;
