import React from 'react';
import _, { get, template } from 'lodash';

import { useLocale } from 'features/Language';

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export const useText = ({ tid, values = {} }) => {
  const { dictionary } = useLocale();
  const compiled = template(get(dictionary, tid));

  return compiled(values);
};

export const useTranslation = () => {
  const { dictionary } = useLocale();
  const t = React.useCallback(
    (tid, values = {}) => {
      const compiled = template(get(dictionary, tid));
      return compiled(values);
    },
    [dictionary]
  );

  return [t];
};

const Text = ({ tid, values = {} }) => {
  const { dictionary } = useLocale();
  const compiled = template(get(dictionary, tid));

  return compiled(values);
};

Text.useText = useText;
Text.useTranslation = useTranslation;

export default Text;
