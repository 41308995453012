const baseUrl = 'rest/';
const serviceName = 'client_EnterpriseService';
export const types = ['Enterprise', 'MemberList', 'EntList', 'EnterpriseList'];

export default (builder) => ({
  // 企业管理员身份列表
  getEnterpriseList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/${serviceName}/search`,
      method: 'post',
      data: { request: data },
    }),
    providesTags: [{ type: 'EntList', id: 'LIST' }],
  }),
  // 移交超级管理员
  getTransferIdentitys: builder.mutation({
    query: (data) => ({
      url: `${baseUrl}services/client_EnterpriseService/transferIdentity`,
      method: 'post',
      data,
    }),
    invalidatesTags: () => [{ type: 'EntList', id: 'LIST' }],
  }),

  // 企业信息
  getEnterpriseInfoById: builder.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/query`,
      method: 'post',
      data: { id },
    }),
    providesTags: [{ type: 'MemberList', id: 'LIST' }],
  }),

  // 根据code获取公司信息
  getEnterpriseNameByCode: builder.mutation({
    query: (code) => ({
      url: `${baseUrl}services/${serviceName}/joinPrepare`,
      method: 'post',
      data: { code },
    }),
  }),

  // 加入企业
  joinEnterprise: builder.mutation({
    query: (code) => ({
      url: `${baseUrl}services/${serviceName}/join`,
      method: 'post',
      data: { code },
    }),
    invalidatesTags: () => [
      { type: 'User_Info', id: 'INFO' },
      { type: 'EntList', id: 'LIST' },
    ],
  }),

  // 获取公司成员列表
  getEnterpriseMember: builder.query({
    query: ({ enterpriseId, search, deactivate = true, limit, offset }) => ({
      url: `${baseUrl}services/${serviceName}/searchEntUser`,
      method: 'post',
      data: { request: { enterpriseId, search, deactivate, limit, offset } },
    }),
    providesTags: [{ type: 'MemberList', id: 'LIST' }],
  }),

  // 停用
  getdeactivateMember: builder.mutation({
    query: (data) => ({
      url: `${baseUrl}services/${serviceName}/EnableEntUser`,
      method: 'post',
      data: { request: data },
    }),
    invalidatesTags: () => [{ type: 'MemberList', id: 'LIST' }],
  }),
  // 获取公司成员列表
  getEnterpriseMembers: builder.query({
    query: ({ enterpriseId, search, deactivate = true, limit, offset }) => ({
      url: `${baseUrl}services/client_EnterpriseService/transferEntUser`,
      method: 'post',
      data: { request: { enterpriseId, search, deactivate, limit, offset } },
    }),
    providesTags: [{ type: 'MemberList', id: 'LIST' }],
  }),

  // 更新企业用户vip类型
  updateMemberType: builder.mutation({
    query: ({ userIds, enterpriseId, vipType, identity }) => ({
      url: `${baseUrl}services/${serviceName}/activeEntUser`,
      method: 'post',
      data: { request: { userIds, enterpriseId, vipType, identity } },
    }),
    invalidatesTags: () => [
      { type: 'MemberList', id: 'LIST' },
      { type: 'User_Info', id: 'INFO' },
    ],
  }),

  // 启停用企业用户
  changeMemberStatus: builder.mutation({
    query: ({ userIds, enterpriseId, deactivate }) => ({
      url: `${baseUrl}services/${serviceName}/EnableEntUser`,
      method: 'post',
      data: { request: { userIds, enterpriseId, deactivate } },
    }),
    invalidatesTags: () => [{ type: 'MemberList', id: 'LIST' }],
  }),

  // 生产成员邀请码
  creatMemberCode: builder.mutation({
    query: ({ number, enterpriseId, effectiveTO }) => ({
      url: `${baseUrl}services/${serviceName}/creatMemberCode`,
      method: 'post',
      data: { request: { number, enterpriseId, effectiveTO } },
    }),
  }),

  // 企业邀请码生成记录
  getCodeRecordList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/${serviceName}/searchCode`,
      method: 'post',
      data,
    }),
    providesTags: () => [{ type: 'EnterpriseList', id: 'LIST' }],
  }),

  // 企业邀请码状态修改
  alterenterprise: builder.mutation({
    query: ({ id, ...data }) => ({
      url: `${baseUrl}services/client_EnterpriseService/updateCode`,
      method: 'post',
      data: {
        request: {
          id,
          ...data,
        },
      },
    }),
    invalidatesTags: () => [{ type: 'EnterpriseList', id: 'LIST' }],
  }),

  // 企业邀请码使用记录
  InvitationCodeRecord: builder.query({
    query: ({ id, ...data }) => ({
      url: `${baseUrl}services/client_EnterpriseService/searchJoinRecord`,
      method: 'post',
      data: {
        id,
        ...data,
      },
    }),
  }),
  // 组织架构列表
  organizationChartList: builder.query({
    query: () => ({
      url: `${baseUrl}entities/mst_enterprise_Organization`,
      method: 'get',
    }),
    providesTags: [{ type: 'EntList', id: 'LIST' }],
  }),
  // 新增组织
  organizationChartNew: builder.mutation({
    query: (e) => ({
      url: `${baseUrl}services/client_EntOrganizeService/saveOrganize`,
      method: 'post',
      data: {
        request: { ...e },
      },
    }),
    providesTags: [{ type: 'EntList', id: 'LIST' }],
  }),
  // 新增组织
  deleteorganizationChart: builder.mutation({
    query: (e) => ({
      url: `${baseUrl}services/client_EntOrganizeService/search`,
      method: 'post',
      data: {
        request: { ...e },
      },
    }),
    providesTags: [{ type: 'EntList', id: 'LIST' }],
  }),
});
