export const types = ['Course', 'Private_Course'];

const baseUrl = 'rest/';
const serviceName = 'client_CourseService';

export default (build) => ({
  // 查询课程列表(Public)
  getCourses: build.query({
    query: ({ data, signal, authorized }) => {
      if (authorized) {
        return {
          url: `${baseUrl}services/${serviceName}/searchEnt`,
          method: 'post',
          data: { request: data },
        };
      }
      return {
        url: `${baseUrl}services/${serviceName}/search`,
        method: 'post',
        data: { request: data },
        signal,
        isPublic: true,
      };
    },
    providesTags: (result) =>
      result?.data?.length
        ? [...result.data.map(({ id }) => ({ type: 'Course', id })), { type: 'Course', id: 'LIST' }]
        : [{ type: 'Course', id: 'LIST' }],
  }),

  // 获取案例附件中心列表
  ShareCourseFileService: build.query({
    query: (data) => ({
      url: `${baseUrl}services/client_ShareFileService/courseSearch`,
      method: 'post',
      data: {
        request: data,
      },
    }),
  }),

  // 查询课程基本信息及大纲(Public)
  getCourse: build.query({
    query: ({ id, token }) => {
      if (token) {
        return {
          url: `${baseUrl}services/${serviceName}/queryChoose`,
          method: 'post',
          data: { id },
        };
      }
      return {
        url: `${baseUrl}services/${serviceName}/query`,
        method: 'post',
        data: { id },
        isPublic: true,
      };
    },
    providesTags: (result, error, { token, id }) => [{ type: token ? 'Private_Course' : 'Course', id }],
  }),

  // 查询课程基本信息及大纲(Private)
  getPrivateCourse: build.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/queryChoose`,
      method: 'post',
      data: { id },
    }),
  }),

  // 根据小节 ID 查询查询课程下的阅读材料(Private)
  getSupplement: build.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/querySectionText`,
      method: 'post',
      data: { id },
    }),
  }),

  // 根据小节 ID 查询查询课程下的视频材料(Private)
  getLecture: build.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/querySectionVideo`,
      method: 'post',
      data: { id },
    }),
  }),

  // 根据小节 ID 查询课程下的案例 ID (Private)
  getCourseCase: build.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/querySectionCase`,
      method: 'post',
      data: { id },
    }),
  }),

  // 选课(Private)
  registerCourse: build.mutation({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/choose`,
      method: 'post',
      data: { id },
    }),
    invalidatesTags: (result, error, id) => [{ type: 'Private_Course', id }], // 需要更新我的课程列表
  }),

  // 查询已选的课程列表(Private)
  getPrivateCourses: build.query({
    query: ({ data, signal }) => ({
      url: `${baseUrl}services/${serviceName}/chooseRecord`,
      method: 'post',
      data: { request: data },
      signal,
    }),
    providesTags: (result) =>
      result?.data?.length
        ? [...result.data.map(({ id }) => ({ type: 'Private_Course', id })), { type: 'Private_Course', id: 'LIST' }]
        : [{ type: 'Private_Course', id: 'LIST' }],
  }),

  // 新建课程
  createCourse: build.mutation({
    query: ({ data, signal }) => ({
      url: `${baseUrl}services/${serviceName}/saveCourse`,
      method: 'post',
      data: { request: data },
      signal,
    }),
    invalidatesTags: () => [{ type: 'Private_Course', id: 'LIST' }], // 需要更新我的课程列表
  }),

  // 查询课程分类
  getCourseCategories: build.query({
    query: ({ signal }) => ({
      url: `${baseUrl}services/client_CategoryService/queryCourse`,
      method: 'post',
      data: {},
      signal,
    }),
  }),

  // 查询用户小节完成状态
  getSubChapterMarked: build.query({
    query: ({ chooseRecordId, subsectionId, signal }) => ({
      url: `${baseUrl}services/client_ChooseSectionService/search`,
      method: 'post',
      data: {
        request: {
          chooseRecordId,
          subsectionId,
        },
      },
      signal,
    }),
    providesTags: () => [{ type: 'client_CourseService', id: 'LIST' }],
  }),

  // 小节学习完成
  addSubChapterMarked: build.mutation({
    query: ({ chooseRecordId, subsectionId, signal }) => ({
      url: `${baseUrl}services/client_CourseService/completeStudy`,
      method: 'post',
      data: {
        request: {
          id: -1,
          chooseRecordId,
          subsectionId,
          schedule: true,
        },
      },
      signal,
    }),
    invalidatesTags: () => [{ type: 'client_CourseService', id: 'LIST' }], // 需要更新我的课程列表
  }),
  // 新建课程
  continueCourseStudy: build.mutation({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/queryJump`,
      method: 'post',
      data: { id },
    }),
    invalidatesTags: () => [{ type: 'Private_Course', id: 'LIST' }], // 需要更新我的课程列表
  }),
});
