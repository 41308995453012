export const types = ['User_Info'];

const baseUrl = 'rest/';
const serviceName = 'client_EmailService';

export default (build) => ({
  // 注册发送验证码
  sendVerificationCode: build.mutation({
    query: (data) => ({
      url: `${baseUrl}services/${serviceName}/sendEmail`,
      method: 'post',
      data,
      isPublic: true,
    }),
  }),
  // 修改邮箱时发送验证码
  sendCodeEmail: build.mutation({
    query: (data) => ({
      url: `${baseUrl}services/${serviceName}/sendEmailInfo`,
      method: 'post',
      data,
    }),
  }),
  // 重置密码时发送验证码
  sendCodePassword: build.mutation({
    query: (data) => ({
      url: `${baseUrl}services/${serviceName}/sendEmailPwd`,
      method: 'post',
      data,
      isPublic: true,
    }),
  }),
  // 获取用户主页信息
  getUserProfile: build.query({
    query: (id) => ({
      url: `${baseUrl}user/info`,
      method: 'post',
      data: { id },
    }),
  }),
  getUserInfo: build.query({
    query: (token) => ({
      // url: 'rest/userInfo',
      // method: 'GET',

      url: `${baseUrl}user/userInfo`,
      method: 'post',
      headers: { authorization: `Bearer ${token}` },
    }),
    providesTags: [{ type: 'User_Info', id: 'INFO' }],
  }),
  // 移交超级管理员
  getTransferIdentity: build.mutation({
    query: (data) => ({
      url: `${baseUrl}services/client_EnterpriseService/transferIdentity`,
      method: 'post',
      data,
    }),
    invalidatesTags: () => [{ type: 'User_Info', id: 'INFO' }],
  }),
  // 获取用户信息
  getUserBasicInfo: build.query({
    query: () => ({
      url: `${baseUrl}user/userInfo`,
      method: 'post',
    }),
    providesTags: [{ type: 'User_Info', id: 'INFO' }],
  }),
  // 更新用户信息
  updateUserInfo: build.mutation({
    query: (data) => ({
      url: `${baseUrl}user/updateInfo`,
      method: 'post',
      data,
    }),
    invalidatesTags: [{ type: 'User_Info', id: 'INFO' }],
  }),
  // 更新用户邮箱
  updateEmail: build.mutation({
    query: (data) => ({
      url: `${baseUrl}user/updateEmail`,
      method: 'post',
      data,
    }),
  }),
  // 更新用户密码
  updatePassword: build.mutation({
    query: (data) => ({
      url: `${baseUrl}user/updatePwd`,
      method: 'post',
      data,
    }),
  }),
  // 重置密码
  resetPassword: build.mutation({
    query: (data) => ({
      url: `${baseUrl}user/resetPwd`,
      method: 'post',
      data,
      isPublic: true,
    }),
  }),

  // 注销账户
  deleteAccount: build.mutation({
    query: (data) => ({
      url: `${baseUrl}user/delete`,
      method: 'post',
      data,
    }),
  }),
  // 校验用户名重复
  existUserName: build.mutation({
    query: (username) => ({
      url: `${baseUrl}login/userNameExist`,
      method: 'post',
      data: { username },
    }),
  }),

  // 校验邮箱重复
  existEmail: build.mutation({
    query: (email) => ({
      url: `${baseUrl}login/emailExist`,
      method: 'post',
      data: { email },
    }),
  }),

  // 获取jupyter相关信息
  jupyterLogin: build.mutation({
    query: () => ({
      url: `${baseUrl}dic/jupyter`,
      method: 'POST',
    }),
  }),
  // 生成用户jupyter空间
  jupyterUser: build.mutation({
    query: (id) => ({
      url: `${baseUrl}user/jupyterUser`,
      method: 'POST',
      data: { id },
    }),
  }),

  // 激活码注册会员
  activationCode: build.mutation({
    query: (code) => ({
      url: `${baseUrl}user/activation`,
      method: 'POST',
      data: { activationCode: code },
    }),
    invalidatesTags: [{ type: 'User_Info', id: 'INFO' }],
  }),

  // 订单记录
  getVipRecordList: build.query({
    query: (data) => ({
      url: `${baseUrl}services/client_RecordService/search`,
      method: 'post',
      data,
    }),
  }),
});
