import enUS from './en-us';
import zhCN from './zh-cn';

export const localeMap = {
  zh: 'zh-cn',
  'zh-cn': 'zh-cn',
};

const local = window?.localStorage?.getItem('locale') || window?.navigator?.language;

export const defaultLocale = localeMap[local?.toLowerCase()] || 'en-us';

export const dictionaryList = {
  'en-us': enUS,
  'zh-cn': zhCN,
};

export const languageOptions = [
  { id: 'en-us', text: 'English' },
  { id: 'zh-cn', text: '中文-简体' },
];
