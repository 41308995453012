import axios from 'axios';

const axiosBaseQuery =
  ({ baseURL, onTokenExpiry } = { baseURL: '' }) =>
  async (axiosOptions, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;
      const locale = getState().common.locale;
      const { headers = {}, isPublic = false, ...restOptions } = axiosOptions || {};
      headers['accept-language'] = locale || 'zh';
      if (token && !restOptions?.auth && !headers?.authorization && !isPublic) {
        headers.authorization = `Bearer ${token}`;
      }
      const request = {
        baseURL,
        withCredentials: false,
        headers,
      };
      if (axiosOptions?.signal) {
        request.signal = axiosOptions?.signal;
      }
      const response = await axios({
        ...request,
        ...restOptions,
      });
      return { data: response?.data };
    } catch (error) {
      if (axios.isCancel(error)) {
        return { error: { message: error.message, request: error.request } };
      }
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.status === 401) {
          // Not logged in or expired
          if (typeof onTokenExpiry === 'function') {
            dispatch(onTokenExpiry());
          }
        }
        return {
          error: { message: 'Internal Server Error', status: error.response.status, response: error.response.data },
        };
      }
      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return { error: { status: 500, message: 'Internal Server Error!', request: error.request } };
      }
      return { error };
    }
  };
export default axiosBaseQuery;
