import React from 'react';
import Avatar from '@mui/material/Avatar';

import { headPortraitColor } from 'common/constans';

const UserImg = ({ name = '', index = 0 }) => (
  <Avatar
    sx={{
      bgcolor: headPortraitColor[index]?.fillColor,
      border: 1,
      borderColor: headPortraitColor[index]?.borderColor,
      color: headPortraitColor[index]?.fontColor,
    }}
  >
    {name && name.charAt(0)}
  </Avatar>
);

export default UserImg;
