const common = {
  home: '首页',
  solvers: '求解器',
  tools: '工具',
  statistics: '统计',
  console: '控制台',
  footer: {
    terms: '使用须知',
    qa: '问题与评论',
    EULA: `用户协议`,
    privacyPolicy: `隐私政策`,
    copyright: `© {{ year }} Cardinal Operations 保留所有权利`,
  },
  form: {
    submit: '提交',
    reset: '重置',
    cancel: '取消',
    refresh: '刷新',
    close: '关闭',
    sendCode: '发送验证码',
    resend: '重新发送',
    ok: '确定',
    delete: '删除',
    clear: '清空',
    view: '查看',
    add: '新增',
    search: '搜索',
    remove: '移除',
    back: '返回',
    upload: '上传',
    filters: '过滤',
    done: '完成',
    edit: '编辑',
    save: '保存',
  },
  ok: '确定',
  'detail-introduction': '详细介绍',
  backHome: '返回首页',
  positionOption1: '学术研究-学者',
  positionOption2: '学术研究-学生',
  positionOption3: '运筹算法工程师',
  positionOption4: '商业分析师',
  positionOption5: '数据科学家',
  positionOption6: 'IT工程师',
  industryOption1: '科研机构',
  industryOption2: '制造行业',
  industryOption3: '金融行业',
  industryOption4: '保险行业',
  industryOption5: '传媒行业',
  industryOption6: '能源行业',
  industryOption7: '政府部门',
  industryOption8: '医疗行业',
  industryOption9: '物流与交通运输行业',
  industryOption10: '航空行业',
  industryOption11: '软件行业',
  industryOption12: '零售行业',
  industryOption13: '通信行业',
  others: '其他',
  fullname: '全称',
  age: '年龄',
  title: '标题',
  company: '公司',
  address: '地址',
  account: '用户名',
  email: '邮箱',
  password: '密码',
  newPassword: '新密码',
  confirmPassword: '再次输入密码',
  name: '姓名',
  nickname: '昵称',
  industry: '行业',
  institute: '公司/学校',
  position: '职位',
  phone: '手机号码',
  region: '使用国家或地区',
  city: '使用城市',
  reasons: '测试原因/使用求解服务的具体场景',
  signin: '登录',
  signout: '退出',
  signup: '注册',
  hasAccount: '已有账户?',
  backSignin: '返回登录',
  resetPassword: '重置密码',
  verificationCode: '验证码',
  forgetPassword: '忘记密码',
  irreversibleActionWarn: '此操作不可撤销，确定？',
  welcome: '你好，欢迎回来!',
  columns: {
    action: '操作',
    id: 'ID',
    creator: '创建人',
    createTime: '创建时间',
    updater: '更新人',
    updateTime: '更新时间',
  },
  empty: '空',
  autoRedirect: '{{ count }} 秒后将自动跳转到首页，',
  redirectImmediately: '立即跳转',
  messages: {
    dragValidateInfo: '按住滑块，拖动到最右边',
    dragValidateSuccess: '验证成功',
    dragValidateRequired: '请拖动滑块进行验证！',
    response: {
      status504: '连接超时(504)，请你稍后重试！',
    },
  },
};

export default common;
