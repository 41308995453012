import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTextField from 'components/form/FormTextField';
import FormPassword from 'components/form/FormPassword';
import FormSendCode from 'components/form/FormSendCode';
import Text from 'components/Text';
import api from 'services/api';
import { useSnackbar } from 'notistack';

const validationSchema = Yup.object({
  email: Yup.string().email('请填写正确类型的邮箱号').required('请填写邮箱号'),
  verificationCode: Yup.string().required('请输入验证码'),
  newPassword: Yup.string()
    .matches('^[a-zA-Z0-9_-]{4,16}$', '仅支持字母,数字及"_-"符号,4-16个字符')
    .max(16, '仅支持字母,数字及"_-"符号,4-16个字符')
    .min(4, '仅支持字母,数字及"_-"符号,4-16个字符')
    .required('请输入新密码'),
  confirmPassword: Yup.string()
    .required('请再次输入新密码')
    .oneOf([Yup.ref('newPassword')], '两次密码不一致'),
});

const ForgetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [sendCode] = api.useSendCodePasswordMutation();
  const [resetPassword, { isLoading }] = api.useResetPasswordMutation();
  const [, setSearchParams] = useSearchParams();

  const formikRef = useRef(null);

  const handleBackLogin = () => {
    setSearchParams({ authMode: 'login' });
  };

  const handleSubmit = (values) => {
    const { email, newPassword, verificationCode } = values;
    resetPassword({
      email,
      newPassword,
      verificationCode,
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar('重置密码成功!', { variant: 'success' });
        handleBackLogin();
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.details || error.message, { variant: 'error' });
      });
  };

  const handleSendCode = (setCount) => {
    const { errors, values } = formikRef.current;
    if (values?.email && !errors.email) {
      sendCode({ email: values.email })
        .unwrap()
        .then(() => {
          setCount(60);
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.details || error.message, { variant: 'error' });
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          newPassword: '',
          confirmPassword: '',
          verificationCode: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ validateField, setFieldTouched }) => (
          <Form>
            <FormTextField
              required
              label={<Text tid="common.email" />}
              name="email"
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
            />
            <FormSendCode
              required
              size="small"
              name="verificationCode"
              label={<Text tid="common.verificationCode" />}
              fullWidth
              variant="outlined"
              margin="normal"
              sendCode={(setCount) => {
                validateField('email').then(() => {
                  setFieldTouched('email', true);
                  handleSendCode(setCount);
                });
              }}
            />
            <FormPassword
              required
              label={<Text tid="common.newPassword" />}
              name="newPassword"
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
            />
            <FormPassword
              required
              label={<Text tid="common.confirmPassword" />}
              name="confirmPassword"
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
            />
            <LoadingButton sx={{ mt: 4 }} type="submit" loading={isLoading} variant="contained" fullWidth>
              <Text tid="common.resetPassword" />
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <Stack sx={{ pt: 2, px: 2 }} direction="row" justifyContent="center">
        <Typography
          sx={{ color: 'primary.main', cursor: 'pointer' }}
          variant="subtitle2"
          gutterBottom
          component="div"
          onClick={handleBackLogin}
        >
          <Text tid="common.backSignin" />
        </Typography>
      </Stack>
    </>
  );
};

export default ForgetPassword;
