import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: 'light', // 主题
  // locale: 'zhCN', // 语种
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // 切换主题
    toggleTheme: (state) => {
      state.theme = state.theme === 'dark' ? 'light' : 'dark';
    },
    // 切换语种
    // setLocale: (state, action) => {
    //   state.locale = action.payload;
    // },
  },
});

export const commonActions = {
  ...commonSlice.actions,
};

export default commonSlice.reducer;
