import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

export default function SelectionCell({ rowData, rowIndex, column }) {
  const handleChange = (e) => {
    const { selectedRowKeys, selectedRows, rowKey, type, onChange } = column;
    const key = rowData[rowKey];

    if (type === 'radio') {
      onChange({
        selectedRowKeys: [key],
        selectedRows: [rowData],
      });
    } else {
      const newSelectedRowKeys = [...selectedRowKeys];
      const newSelectedRows = [...selectedRows];
      if (e.target.checked) {
        if (!selectedRowKeys.includes(key)) {
          newSelectedRowKeys.push(key);
          newSelectedRows.push(rowData);
        }
      } else {
        const index = selectedRowKeys.indexOf(key);
        if (index > -1) {
          newSelectedRowKeys.splice(index, 1);
          newSelectedRows.splice(index, 1);
        }
      }
      onChange({
        selectedRowKeys: newSelectedRowKeys,
        selectedRows: newSelectedRows,
      });
    }
  };

  const { selectedRowKeys, rowKey, getCheckboxProps } = column;
  let restCheckboxProps = {};
  if (typeof getCheckboxProps === 'function') {
    restCheckboxProps = getCheckboxProps(rowData);
  }
  const checked = selectedRowKeys.includes(rowData[rowKey]);

  if (column.type === 'radio') {
    return (
      <Radio checked={checked} onClick={(e) => e.stopPropagation()} onChange={handleChange} {...restCheckboxProps} />
    );
  }
  return <Checkbox checked={checked} onChange={handleChange} style={{ height: '21px' }} {...restCheckboxProps} />;
}
