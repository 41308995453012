export const types = [
  'mst_Course',
  'mst_course_Data',
  'mst_course_Chapter',
  'mst_course_chapter_Subsection',
  'mst_course_subsection_Text',
  'mst_course_subsection_Video',

  'mst_Case',
  'mst_case_Detail',
  'mst_case_Slide',
  'mst_case_Code',
  'mst_case_zip_File',
  'mst_case_data_File',
];

const baseUrl = 'rest/';

export default (builder) => ({
  // jmix 基础接口
  getMetadataEntities: builder.query({
    query: () => ({ url: `${baseUrl}metadata/entities` }),
    transformResponse: (response) => response.sort((a, b) => a?.entityName?.localeCompare(b?.entityName)),
  }),
  getMetadataEntity: builder.query({
    query: (entityName) => ({ url: `${baseUrl}metadata/entities/${entityName}` }),
  }),
  getEntityViews: builder.query({
    query: (entityName) => ({ url: `${baseUrl}metadata/entities/${entityName}/views` }),
  }),
  getEntityView: builder.query({
    query: ({ entityName, fetchPlanName }) => ({
      url: `${baseUrl}metadata/entities/${entityName}/fetchPlans/${fetchPlanName}`,
    }),
  }),
  getEntitiesMessages: builder.query({
    query: () => ({ url: `${baseUrl}messages/entities` }),
  }),
  getEnums: builder.query({
    query: () => ({ url: `${baseUrl}metadata/enums` }),
  }),
  getMessagesEnums: builder.query({
    query: () => ({ url: `${baseUrl}messages/enums` }),
  }),
  loadServices: builder.query({
    query: () => ({ url: `${baseUrl}services` }),
  }),
  loadService: builder.query({
    query: (name) => ({ url: `${baseUrl}services/${name}` }),
  }),

  // 获取 entity 列表数据
  searchEntities: builder.query({
    query: ({ entityName, entityFilter, params, signal }) => {
      let path = `${baseUrl}entities/${entityName}`;
      let method = 'GET';
      let data = null;
      if (entityFilter?.conditions?.length) {
        path = `${path}/search`;
        method = 'POST';
        data = {
          ...params,
          filter: entityFilter,
        };
      }
      return {
        url: path,
        method,
        params,
        data,
        signal,
        transformResponse: [(res) => JSON.parse(res)],
      };
    },
    // providesTags: (result, error, arg) => {
    //   const type = arg?.entityName;
    //   return result ? [...result.map(({ id }) => ({ type, id })), { type, id: 'LIST' }] : [{ type, id: 'LIST' }];
    // },
  }),

  // 获取 entity 列表数据并返回总条数
  searchEntitiesWithCount: builder.query({
    query: ({ entityName, entityFilter, options, signal }) => {
      let count;
      let path = `${baseUrl}entities/${entityName}`;
      let method = 'GET';
      const params = {
        ...options,
        returnCount: true,
      };
      let data = null;
      if (entityFilter?.conditions?.length) {
        path = `${path}/search`;
        method = 'POST';
        data = {
          ...params,
          filter: entityFilter,
        };
      }
      return {
        url: path,
        method,
        params,
        data,
        signal,
        transformResponse: [
          (res, headers) => {
            const result = JSON.parse(res);
            count = parseInt(headers['X-Total-Count'], 10);
            return { result, count };
          },
        ],
      };
    },
    // providesTags: ({ result }, error, arg) => {
    //   const type = arg?.entityName;
    //   return result ? [...result.map(({ id }) => ({ type, id })), { type, id: 'LIST' }] : [{ type, id: 'LIST' }];
    // },
  }),

  // 获取 entity 详情数据
  loadEntity: builder.query({
    query: ({ entityName, id, params, signal }) => ({
      url: `${baseUrl}entities/${entityName}/${id}`,
      method: 'get',
      params,
      signal,
    }),
    // providesTags: (result, error, { entityName, id }) => [{ type: entityName, id }],
  }),

  // 调用 service 接口
  invokeService: builder.query({
    query: ({ serviceName, methodName, data, params, signal, isPublic }) => ({
      url: `${baseUrl}services/${serviceName}/${methodName}`,
      method: 'post',
      params,
      data,
      signal,
      isPublic,
    }),
  }),

  // 创建 entity
  createEntity: builder.mutation({
    query: ({ entityName, data, params }) => ({
      url: `${baseUrl}entities/${entityName}`,
      method: 'post',
      data: { ...data, id: -1 },
      params,
    }),
  }),
  // 批量创建 entity
  createEntities: builder.mutation({
    query: ({ entityName, data, params }) => ({
      url: `${baseUrl}entities/${entityName}`,
      method: 'post',
      data,
      params,
    }),
  }),
  // 更新 entity
  updateEntity: builder.mutation({
    query: ({ entityName, data, params }) => ({
      url: `${baseUrl}entities/${entityName}/${data.id}`,
      method: 'put',
      data,
      params,
    }),
  }),
  // 批量更新 entity
  updateEntities: builder.mutation({
    query: ({ entityName, data, params }) => ({
      url: `${baseUrl}entities/${entityName}`,
      method: 'put',
      data,
      params,
    }),
  }),
  // 删除 entity
  deleteEntity: builder.mutation({
    query: ({ entityName, id }) => ({
      url: `${baseUrl}entities/${entityName}/${id}`,
      method: 'delete',
    }),
  }),

  // 查询可选的查询条件：如标签等
  getConditions: builder.query({
    query: ({ signal }) => ({
      url: `${baseUrl}services/client_ConditionService/search`,
      method: 'post',
      signal,
    }),
  }),

  // 查询技能标签列表
  getSkills: builder.query({
    query: ({ signal }) => ({
      url: `${baseUrl}services/client_ConditionService/search`,
      method: 'post',
      data: { tagType: 'SKILL' },
      signal,
    }),
  }),

  // 查询其他标签列表
  getTags: builder.query({
    query: ({ signal }) => ({
      url: `${baseUrl}services/client_ConditionService/search`,
      method: 'post',
      data: { tagType: null },
      signal,
    }),
  }),
  // 上传图片
  uploadFileImg: builder.mutation({
    query: ({ data, signal }) => ({
      url: `${baseUrl}file/images`,
      method: 'post',
      data,
      signal,
    }),
  }),
});
