import React from 'react';
import { useField } from 'formik';
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

const FormCheckbox = ({ name, label, helperText, CheckboxProps: checkboxProps, ...restProps }) => {
  const [field, meta, helpers] = useField(name);

  const error = meta.touched && meta.error;
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  const errorDom = React.useMemo(() => {
    if (error) {
      <FormHelperText error>{error}</FormHelperText>;
    }
    if (helperText) {
      <FormHelperText>{error}</FormHelperText>;
    }
    return null;
  }, [error, helperText]);

  return (
    <FormControl {...restProps}>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={handleChange} name="name" color="primary" {...checkboxProps} />}
        label={label}
      />
      {errorDom}
    </FormControl>
  );
};

export default FormCheckbox;
