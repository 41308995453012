import React, { createContext, useContext, useState, useMemo } from 'react';

import { defaultLocale, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  locale: defaultLocale,
  dictionary: dictionaryList[defaultLocale],
  setLocale: () => {},
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const languageContext = useContext(LanguageContext);
  const [locale, setLocale] = useState(languageContext.locale);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = useMemo(
    () => ({
      locale,
      dictionary,
      setLanguage: (newLocale) => {
        setLocale(newLocale); // it will update the language in state
        setDictionary(dictionaryList[newLocale]);
        // window?.localStorage?.setItem('locale', locale);
        // 通过重新加载网页的方式解决 api 重新调用问题
        // window.location.reload();
      },
    }),
    [locale, dictionary]
  );

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
}

export function useLocale() {
  return useContext(LanguageContext);
}
