import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

export default function ImagePopper({ alt, image, poperImage }) {
  const id = React.useId();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Box
        // sx={{ display: { md: 'none', lg: 'inline-block' } }}
        sx={{ display: 'inline-block' }}
        component="img"
        alt={alt}
        src={poperImage}
        width={80}
        height={80}
      />
      <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {/* <Box component="img" alt={alt} src={image} width={54} height={54} /> */}
        <Typography variant="subtitle2" fontSize={12} textAlign="center">
          {alt}
        </Typography>
      </Box>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box sx={{ border: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
          <Box component="img" alt={alt} src={poperImage} width={200} height={200} />
        </Box>
      </Popper>
    </Box>
  );
}
