import React from 'react';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog({ size = 'sm', ...restProps }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(size));

  return <Dialog fullScreen={fullScreen} {...restProps} />;
}
