import React from 'react';
import { useField } from 'formik';
import { FormControl, InputLabel, FormHelperText, Select, MenuItem } from '@mui/material';

const FormSelect = ({ name, label, helperText, options = [], InputLabelProps: inputLabelProps, ...restProps }) => {
  const [field, meta, helpers] = useField(name);

  const error = meta.touched && meta.error;
  const { value } = meta;
  const { setValue } = helpers;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const errorDom = React.useMemo(() => {
    if (error) {
      <FormHelperText error>{error}</FormHelperText>;
    }
    if (helperText) {
      <FormHelperText>{error}</FormHelperText>;
    }
    return null;
  }, [error, helperText]);

  return (
    <FormControl {...restProps}>
      {label && (
        <InputLabel error={!!error} {...inputLabelProps}>
          {label}
        </InputLabel>
      )}
      <Select value={value} onChange={handleChange} label={label}>
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {errorDom}
    </FormControl>
  );
};

export default FormSelect;
