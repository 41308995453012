import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

import Terms from 'components/links/Terms';
import Privacy from 'components/links/Privacy';
import ExternalLink from 'components/links/ExternalLink';
import Text from 'components/Text';
import coridmWechatPng from 'assets/images/coridm-wechat.png';
import shanshuWechatPng from 'assets/images/shanshu-wechat.png';
import wechatPng from 'assets/images/wechat.png';
import zhihuPng from 'assets/images/zhihu.png';
import shanshuLogo from 'assets/images/shanshu.png';
import bilibiiLogo from 'assets/images/bilibii.png';
import zhihuLogo from 'assets/images/zhihu-new.png';
import coptLogo from 'assets/images/copt.png';
import coapLogo from 'assets/images/coap.png';
import caseLabLogo from 'assets/images/case-lab.png';

import { useLocale } from 'features/Language';
import ImagePopper from './ImagePopper';

export default function GlobalFooter() {
  const { locale } = useLocale();

  return (
    <Box component="footer" sx={{ bgcolor: 'background.gray' }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: 4,
          pb: 2,
          px: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 4,
            xl: 4,
          },
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={3.5} lg={3.5}>
            <Box>
              <Typography mb={2} fontSize={15} fontWeight={600}>
                关于CORIDM
              </Typography>
              <Typography mr={{ xs: 0, sm: 2, md: 5 }} variant="body2" fontSize={13}>
                CORIDM（Center For Operations Research And Intelligent Decision
                Making）是杉数科技经过精心打磨与内容沉淀推出的运筹与智能决策教学平台。CORIDM教学平台根植于杉数科技深厚的技术能力和丰富的行业经验，将课程+案例+实践三者进行有机结合，致力于创新学习体验，变革教学方式。{' '}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={1.5} lg={1.5}>
            <Typography mb={2} fontSize={15} fontWeight={600}>
              网站导航
            </Typography>
            <Stack direction="column" spacing={0.5}>
              <Typography variant="subtitle2">
                <Link color="inherit" underline="hover" href="/courses">
                  课程
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link color="inherit" underline="hover" href="/cases">
                  案例
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link color="inherit" underline="hover" href="/competitions">
                  竞赛
                </Link>
              </Typography>
              <Typography variant="subtitle2">
                <Link color="inherit" underline="hover" href="/lecture">
                  讲座
                </Link>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} md={3} lg={2.8}>
            <Typography mb={2} fontSize={15} fontWeight={600}>
              友情链接
            </Typography>
            <Stack direction="column" spacing={1}>
              <Box display="flex">
                <Box component="img" alt="杉数科技官网" src={shanshuLogo} width={20} height={19} mr={1} />
                <Typography variant="subtitle2">
                  <ExternalLink href="https://shanshu.ai/">杉数科技官网</ExternalLink>
                </Typography>
              </Box>
              <Box display="flex">
                <Box component="img" alt="COPT" src={coptLogo} width={20} height={19} mr={1} />
                <Typography variant="subtitle2">
                  <ExternalLink href="https://shanshu.ai/solver">COPT 求解器</ExternalLink>
                </Typography>
              </Box>
              <Box display="flex">
                <Box component="img" alt="COAP" src={coapLogo} width={20} height={19} mr={1} />
                <Typography variant="subtitle2">
                  <ExternalLink href="https://www.coap.online">COAP 优化算法服务平台</ExternalLink>
                </Typography>
              </Box>
              <Box display="flex">
                <Box component="img" alt="COAP" src={caseLabLogo} width={20} height={20} mr={1} />
                <Typography variant="subtitle2">
                  <ExternalLink href="https://case-lab.d2d.ai/">杉数案例中心</ExternalLink>
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <Typography mb={2} fontSize={15} fontWeight={600}>
              关注或联系我们
            </Typography>
            <Stack direction={{ xs: 'column', lg: 'row' }} spacing={1}>
              <Box sx={{ display: 'flex', flexDirection: 'row', mr: 2 }}>
                <ImagePopper alt="杉数教学平台" image={wechatPng} poperImage={coridmWechatPng} />
                <ImagePopper alt="杉数科技" image={zhihuPng} poperImage={shanshuWechatPng} />
              </Box>
              <Stack spacing={1} mt={0.5}>
                <Box display="flex">
                  <Box component="img" alt="bilibili：杉数教学平台" src={bilibiiLogo} width={20} height={20} mr={1} />
                  <Typography variant="subtitle2">
                    <ExternalLink href="https://space.bilibili.com/1051711374">杉数教学平台</ExternalLink>
                  </Typography>
                </Box>
                <Box display="flex">
                  <Box component="img" alt="知乎：杉数教学平台" src={zhihuLogo} width={20} height={20} mr={1} />
                  <Typography variant="subtitle2">
                    <ExternalLink href="https://www.zhihu.com/org/qing-tian-yan-jiu-yuan-64/posts">
                      杉数教学平台
                    </ExternalLink>
                  </Typography>
                </Box>
                <Box display="flex">
                  <LocalPhoneOutlinedIcon fontSize="small" />
                  <Typography ml={1} variant="subtitle2">
                    400-680-5680
                  </Typography>
                </Box>
                <Box display="flex">
                  <EmailOutlinedIcon fontSize="small" />
                  <Typography ml={1} variant="subtitle2">
                    coridmsupport@shanshu.ai
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography fontSize={13}>
            <Text tid="common.footer.copyright" values={{ year: new Date().getFullYear() }} />
          </Typography>
          {/* <Typography fontSize={13}>京公网安备 11010102005511号</Typography>
          <Typography fontSize={13}>苏ICP备17008061号</Typography> */}
          <Typography fontSize={13}>
            <Terms language={locale} />
          </Typography>
          <Typography fontSize={13}>
            <Privacy language={locale} />
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
