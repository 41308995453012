import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Text from 'components/Text';
import FormTextField from 'components/form/FormTextField';
import FormPassword from 'components/form/FormPassword';

import { authActions } from 'store/authSlice';
import api from 'services/api';

const SignIn = () => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [login] = api.useLoginMutation();

  const handleSubmit = ({ account, password }, { setSubmitting }) => {
    login({
      username: account,
      password,
    })
      .unwrap()
      .then((res) => {
        dispatch(authActions.setCredentials(res));
      })
      .catch((error) => {
        enqueueSnackbar('用户名或密码错误', { variant: 'error' });
        // enqueueSnackbar(error?.response?.details || error.message, { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const forgetPassword = () => {
    setSearchParams({ authMode: 'reset' });
  };

  const signup = () => {
    setSearchParams({ authMode: 'signup' });
  };

  return (
    <>
      <Formik
        initialValues={{
          account: '',
          password: '',
          g_recaptcha_response: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormTextField
              name="account"
              size="small"
              label="用户名/邮箱"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormPassword
              name="password"
              label={<Text tid="common.password" />}
              margin="normal"
              fullWidth
              size="small"
            />
            <Typography
              sx={{ display: 'inline-block', color: 'primary.main', cursor: 'pointer' }}
              variant="subtitle2"
              gutterBottom
              component="div"
              onClick={forgetPassword}
            >
              <Text tid="common.forgetPassword" />
            </Typography>
            <LoadingButton sx={{ mt: 4 }} loading={isSubmitting} variant="contained" type="submit" fullWidth>
              <Text tid="common.signin" />
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <Stack sx={{ pt: 2, px: 2 }} direction="row" justifyContent="center">
        <Typography variant="body2" gutterBottom component="div">
          没有账号？
        </Typography>
        <Typography
          sx={{ color: 'primary.main', cursor: 'pointer' }}
          variant="subtitle2"
          gutterBottom
          component="div"
          onClick={signup}
        >
          注册
        </Typography>
      </Stack>
    </>
  );
};

export default SignIn;
