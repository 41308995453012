import React, { useRef } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';

import Text from 'components/Text';
import Terms from 'components/links/Terms';
import Privacy from 'components/links/Privacy';
import FormTextField from 'components/form/FormTextField';
import FormPassword from 'components/form/FormPassword';
import FormSelect from 'components/form/FormSelect';
import FormSendCode from 'components/form/FormSendCode';
import FormChip from 'components/form/FormChip';
import FormCheckbox from 'components/form/FormCheckbox';
import { useLocale } from 'features/Language';
import { authActions } from 'store/authSlice';
import api from 'services/api';
import { userSourceOption, interestOptions } from 'common/constans';

const validationSchema = Yup.object({
  account: Yup.string()
    .matches('^[a-zA-Z0-9_-]{0,}$', '仅支持字母,数字及"_-"符号')
    .max(32, '最多填写32个字符')
    .required('请填写用户名'),
  email: Yup.string().email('请填写正确类型的邮箱号').required('请填写邮箱号'),
  mobile: Yup.number().typeError('请填写正确类型的手机号'),
  privacy: Yup.boolean().oneOf([true], '请先同意用户协议和隐私政策'),
  verificationCode: Yup.string().required('请输入验证码'),
  password: Yup.string()
    .matches('^[a-zA-Z0-9_-]{4,16}$', '仅支持字母,数字及"_-"符号,4-16个字符')
    .max(16, '仅支持字母,数字及"_-"符号,4-16个字符')
    .min(4, '仅支持字母,数字及"_-"符号,4-16个字符')
    .required('请输入新密码'),
  confirmPassword: Yup.string()
    .required('请再次输入新密码')
    .oneOf([Yup.ref('password')], '两次密码不一致'),
});

const SignUp = () => {
  const { locale } = useLocale();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const formikRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const [signUp] = api.useSignUpMutation();
  const [existUserName] = api.useExistUserNameMutation();
  const [existEmail] = api.useExistEmailMutation();

  const [sendCode] = api.useSendVerificationCodeMutation();
  const [login] = api.useLoginMutation();

  const handleLogin = ({ account, password }) => {
    login({
      username: account,
      password,
    })
      .unwrap()
      .then((res) => {
        dispatch(authActions.setCredentials(res));
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.details || error.message, { variant: 'error' });
      });
  };

  const handleBackLogin = () => {
    setSearchParams({ authMode: 'login' });
  };

  const handleSendCode = (setCount) => {
    const { errors, values } = formikRef.current;
    if (values?.email && !errors.email) {
      sendCode({ email: values.email })
        .unwrap()
        .then(() => {
          setCount(60);
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.details || error?.message, { variant: 'error' });
        });
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const { account, email, password, verificationCode, userSource, userInterest, mobile, privacy } = values;
    signUp({ username: account, email, password, verificationCode, userSource, userInterest, mobile, privacy })
      .unwrap()
      .then(() => {
        enqueueSnackbar('注册成功！', { variant: 'success' });
        // handleBackLogin();
        handleLogin({ account, password });
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.details || error.message, { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUserNameBlur = (e, value, error, setError) => {
    if (value && !error) {
      existUserName(value)
        .unwrap()
        .then((res) => {
          if (res?.message === 'user.exists.username') {
            setError('用户名已存在');
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.details || error?.message, { variant: 'error' });
        });
    }
  };

  const handleEmailBlur = (e, value, error, setError) => {
    if (value) {
      existEmail(value)
        .unwrap()
        .then((res) => {
          if (res?.message === 'user.email.error') {
            setError('请填写正确类型的邮箱号');
          }
          if (res?.message === 'user.email.exists') {
            setError('邮箱已被注册');
          }
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.details || error?.message, { variant: 'error' });
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          account: '',
          email: '',
          password: '',
          confirmPassword: '',
          privacy: false,
          verificationCode: '',
          userSource: '',
          userInterest: [],
          mobile: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting, validateField, setFieldTouched }) => (
          <Form>
            <FormTextField
              required
              size="small"
              name="account"
              label={`用户名(字母、数字、"_-"组成,确认后不可修改)`}
              fullWidth
              variant="outlined"
              margin="normal"
              onBlur={handleUserNameBlur}
            />
            <FormTextField
              required
              size="small"
              name="email"
              label={<Text tid="common.email" />}
              fullWidth
              variant="outlined"
              margin="normal"
              onBlur={handleEmailBlur}
            />
            <FormSendCode
              required
              size="small"
              name="verificationCode"
              label={<Text tid="common.verificationCode" />}
              fullWidth
              variant="outlined"
              margin="normal"
              sendCode={(setCount) => {
                validateField('email').then(() => {
                  setFieldTouched('email', true);
                  handleSendCode(setCount);
                });
              }}
            />
            <FormPassword
              required
              label={<Text tid="common.password" />}
              name="password"
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
            />
            <FormPassword
              required
              name="confirmPassword"
              label={<Text tid="common.confirmPassword" />}
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
            />
            <FormSelect
              name="userSource"
              label="你从哪里了解到本网站"
              fullWidth
              margin="normal"
              size="small"
              options={userSourceOption}
            />
            <FormChip
              margin="normal"
              chipProps={{ size: 'small' }}
              name="userInterest"
              label="感兴趣的领域"
              options={interestOptions}
            />
            <FormTextField
              name="mobile"
              label="联系方式(手机号)"
              fullWidth
              variant="outlined"
              margin="normal"
              size="small"
            />
            <FormCheckbox
              size="small"
              name="privacy"
              CheckboxProps={{
                size: 'small',
              }}
              label={
                <Stack sx={{ fontSize: 14 }} direction="row" alignItems="center" spacing={1}>
                  <span>
                    <Text tid="signup.signUpAgreement" />
                  </span>
                  <Terms language={locale} />
                  <span>
                    <Text tid="signup.signUpAgreementAnd" />
                  </span>
                  <span>
                    <Privacy language={locale} />
                  </span>
                </Stack>
              }
            />
            <ErrorMessage name="privacy">{(error) => <FormHelperText error>{error}</FormHelperText>}</ErrorMessage>
            <LoadingButton sx={{ mt: 1 }} loading={isSubmitting} variant="contained" fullWidth type="submit">
              <Text tid="common.signup" />
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <Stack sx={{ pt: 1, px: 2 }} direction="row" justifyContent="center">
        <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="body2" component="div">
          已是 CORIDM 用户
        </Typography>
        <Button size="small" onClick={handleBackLogin}>
          登录
        </Button>
      </Stack>
    </>
  );
};

export default SignUp;
