const baseUrl = 'rest/';
const serviceName = 'client_EntOrganizeService';
export const types = ['MembersList', 'organizationMember', 'MemberList'];

export default (builder) => ({
  // 组织架构列表
  organizationChartList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_EntOrganizeService/search`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    providesTags: [{ type: 'MembersList', id: 'LIST' }],
  }),
  // 新增组织
  organizationChartNew: builder.mutation({
    query: (e) => ({
      url: `${baseUrl}services/client_EntOrganizeService/saveOrganize`,
      method: 'post',
      data: {
        request: { ...e },
      },
    }),
    invalidatesTags: () => [{ type: 'MembersList', id: 'LIST' }],
  }),
  // 删除组织
  deleteorganizationChart: builder.mutation({
    query: (e) => ({
      url: `${baseUrl}services/client_EntOrganizeService/delete`,
      method: 'post',
      data: {
        id: e.id,
      },
    }),
    invalidatesTags: () => [{ type: 'MembersList', id: 'LIST' }],
  }),
  // 组织内成员列表
  organizationMemberList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_UserEntOrganizeService/searchOrganize`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    providesTags: [{ type: 'organizationMember', id: 'LIST' }],
  }),
  // 组织成员列表
  MemberList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_UserEntOrganizeService/searchEntUser`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    providesTags: [{ type: 'MemberList', id: 'LIST' }],
    invalidatesTags: [{ type: 'organizationMember', id: 'LIST' }],
  }),
  // 添加新成员
  memberNew: builder.mutation({
    query: (data) => ({
      url: `${baseUrl}services/client_UserEntOrganizeService/joinOrganize`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    invalidatesTags: [
      { type: 'organizationMember', id: 'LIST' },
      { type: 'MemberList', id: 'LIST' },
    ],
  }),
  // 移除成员
  deleteMember: builder.mutation({
    query: (data) => ({
      url: `${baseUrl}services/client_UserEntOrganizeService/removeOrganize`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    invalidatesTags: [
      { type: 'organizationMember', id: 'LIST' },
      { type: 'MemberList', id: 'LIST' },
    ],
  }),

  getEnterprisesMember: builder.query({
    query: ({ enterpriseId, search, deactivate = true, limit, offset }) => ({
      url: `${baseUrl}services/client_EnterpriseService/searchEntUser`,
      method: 'post',
      data: { request: { enterpriseId, search, deactivate, limit, offset } },
    }),
    transformResponse: (response) => response.data.map((item) => ({ text: item?.nickName, value: item?.id })),
  }),
});
