import HttpsSharpIcon from '@mui/icons-material/HttpsSharp';
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import React from 'react';

// 排序选项
export const sortOptions = [
  { value: 'last-updated', label: '最近更新' },
  { value: 'most-popular', label: '最受欢迎' },
  { value: 'rating', label: '评分' },
  { value: 'name', label: '名称(A-Z)' },
];

// 默认排序方式
export const defaultSortBy = 'last-updated';

// 难度选项
export const difficultyFilters = [
  { value: 1, label: '入门' },
  { value: 2, label: '简单' },
  { value: 3, label: '中级' },
  { value: 4, label: '高级' },
];
// 排序选项
export const orderFilters = [
  { value: 'HEAT', label: '最高热度' },
  { value: 'TIME', label: '最新发布' },
];

// 新鲜度
export const freshnessFilters = [
  { value: 'day', label: '最近三天' },
  { value: 'week', label: '最近一周' },
  { value: 'month', label: '最近一个月' },
];

export const userSourceOption = [
  {
    value: 'shanshuWebsite',
    text: '杉数官网',
  },
  {
    value: 'lecture',
    text: '讲座',
  },
  {
    value: 'contest',
    text: '比赛',
  },
  {
    value: 'officialAccounts',
    text: '公众号',
  },
  {
    value: 'other',
    text: '其他',
  },
];

export const interestOptions = [
  { value: 'copt', text: 'COPT求解器' },
  { value: 'supplyChain', text: '供应链' },
  { value: 'operationsResearch', text: '运筹学' },
  { value: 'optimizationAgo', text: '优化算法' },
  { value: 'realCase', text: '真实案例' },
];

// 课程小节类型
export const subSectionTypes = {
  TEXT: 'supplement',
  VIDEO: 'lecture',
  CASE: 'case',
};

// 附件中心搜索选项
export const AttachmentType = [
  { text: '全部', value: 'all' },
  { text: 'DOC', value: 'doc' },
  { text: 'XLS', value: 'xls' },
  { text: 'PPT', value: 'ppt' },
  { text: 'PDF', value: 'pdf' },
  { text: 'ZIP', value: 'zip' },
  { text: '其他', value: 'other' },
];

export const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

// 讲座举办类型
export const lectureForms = {
  ONLINE: '线上',
  OFFLINE: '线下',
  SYNCHRONIZE: '线上线下同步',
};

// 首页通告uid
export const noticeId = '843ee7c1-236f-4c06-b129-4d903bc2d168';

// 用户类型 普通用户 企业用户
export const userTypeMap = {
  ORDINARY: 'ordinary',
  ENTERPRISE: 'enterprise',
};

// vip类型 普通 vip 超级vip
export const vipTypeMap = {
  ORDINARY: 'ordinary',
  VIP: 'vip',
  SVIP: 'svip',
};
// vip类型 中
export const vipTypeMapCN = {
  ordinary: '普通用户',
  vip: '企业高级会员',
  svip: '企业超级会员',
};

export const vipTypOptions = [
  {
    text: '普通用户',
    value: 'ordinary',
  },
  {
    text: '企业高级会员',
    value: 'vip',
  },
];

export const RoleIdentity = [
  {
    text: '管理员',
    value: 'ADMIN',
  },
  {
    text: '普通成员',
    value: 'MEMBER',
  },
];

// 企业用户类型 admin member
export const enterpriseUserType = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  SUPER: 'SUPER',
};

// 企业用户类型 admin member
export const enterpriseUserTypCN = {
  ADMIN: '管理员',
  MEMBER: '成员',
  SUPER: '超级管理员',
};
// 用户无头像时使用
export const headPortraitColor = [
  { fillColor: '#EDF2FD', borderColor: '#CAD7F3', fontColor: '#597ED0' },
  { fillColor: '#F7E4E4', borderColor: '#E0C4C4', fontColor: '#AE7474' },
  { fillColor: '#E0F1EA', borderColor: '#B6C7BF', fontColor: '#85A597' },
  { fillColor: '#EEE3F2', borderColor: '#C8AFD0', fontColor: '#9F84A9' },
  { fillColor: '#ECF6F8', borderColor: '#9CBDC4', fontColor: '#537C84' },
  { fillColor: '#FEF0E8', borderColor: '#FADBCC', fontColor: '#D8A890' },
  { fillColor: '#E7E3F2', borderColor: '#B9B3E0', fontColor: '#766FA4' },
  { fillColor: '#E3EDF2', borderColor: '#A5CCDF', fontColor: '#74A6BE' },
  { fillColor: '#EFF2E3', borderColor: '#C0C99D', fontColor: '#858F5D' },
  { fillColor: '#D9E2ED', borderColor: '#AAC0DD', fontColor: '#7E96B5' },
];

// 课程周，章节小节锁状态
export const readLockType = {
  HAVELOCK: <LockOpenSharpIcon />,
  LOCK: <HttpsSharpIcon />,
  UNLOCK: <div style={{ width: 23.98 }}> </div>,
};
