const baseUrl = 'rest/';
const serviceName = 'client_EntCaseCourseService';
export const types = ['EnterpriseCourses', 'BusinessCase'];

export default (builder) => ({
  // 企业课程列表
  enterpriseCoursesList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_EntCaseCourseService/searchCourse`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    providesTags: [{ type: 'EnterpriseCourses', id: 'LIST' }],
  }),
  // 课程权限修改
  enterpriseCoursesJurisdiction: builder.mutation({
    query: (data) => ({
      url: `${baseUrl}services/client_EntCaseCourseService/updateCoursePer`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    invalidatesTags: [{ type: 'EnterpriseCourses', id: 'LIST' }],
  }),

  // 企业课程列表
  BusinessCaseList: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_EntCaseCourseService/searchCase`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    providesTags: [{ type: 'BusinessCase', id: 'LIST' }],
  }),
  // 课程权限修改
  BusinessCaseJurisdiction: builder.mutation({
    query: (data) => ({
      url: `${baseUrl}services/client_EntCaseCourseService/updateCasePer`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    invalidatesTags: [{ type: 'BusinessCase', id: 'LIST' }],
  }),
  // 数据管理企业案例接口
  BusinessCaselist: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_EntOrganizeService/dataSearch`,
      method: 'post',
      data: {
        request: {
          ...data,
        },
      },
    }),
    invalidatesTags: [{ type: 'BusinessCase', id: 'LIST' }],
  }),

  // 数据管理企业案例权限控制下拉多选
  BusinessCaseTabs: builder.query({
    query: (data) => ({
      url: `${baseUrl}services/client_EntCaseCourseService/getCaseTabs`,
      method: 'post',
      data: {
        ...data,
      },
    }),
    invalidatesTags: [{ type: 'BusinessCase', id: 'LIST' }],
    transformResponse: (response) =>
      response.map((item) => ({
        text: item.tabName,
        value: item?.id,
      })),
  }),
});
