import common from './common';
import home from './home';
import signup from './signup';
import profile from './profile';
import settings from './settings';
import competition from './competition';

const dictionary = {
  common,
  home,
  signup,
  profile,
  settings,
  competition,
};

export default dictionary;
