import React from 'react';
import Box from '@mui/material/Box';

import GlobalAppBar from 'components/header/GlobalAppBar';
import GlobalFooter from 'components/footer/GlobalFooter';
import FullscreenSpinner from 'components/spinner/FullscreenSpinner';

const AppLayout = ({ loading, menu, theme, children }) =>
  loading ? (
    <FullscreenSpinner />
  ) : (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <GlobalAppBar rightLinks={menu} theme={theme} />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children}</Box>
      <GlobalFooter />
    </Box>
  );

export default AppLayout;
