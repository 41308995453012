import React, { forwardRef } from 'react';
// import PropTypes from 'prop-types';
import Table, { Column } from 'react-base-table';
import 'react-base-table/styles.css';
import cn from 'classnames';
import ReactDOMServer from 'react-dom/server';
import Box from '@mui/material/Box';
import SwitchLeftRoundedIcon from '@mui/icons-material/SwitchLeftRounded';
import SwitchRightRoundedIcon from '@mui/icons-material/SwitchRightRounded';

import AutoResizer from './AutoResizerStyle';
import MuiTableCell from './MuiTableCell';
import SelectionCell from './SelectionCell';
import SelectionHeader from './SelectionHeader';

const Empty = () => (
  <Box width={1} py={6} textAlign="center" fontSize={24}>
    {' '}
  </Box>
);

const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

const SortIndicator = ({ sortOrder, className, style }) => {
  const cls = cn('BaseTable__sort-indicator', className, {
    'BaseTable__sort-indicator--descending': sortOrder === SortOrder.DESC,
  });
  return (
    <div
      className={cls}
      style={{
        userSelect: 'none',
        width: '16px',
        height: '16px',
        lineHeight: '16px',
        textAlign: 'center',
        ...style,
      }}
    >
      {sortOrder === 'desc' ? (
        <SwitchLeftRoundedIcon sx={{ transform: 'rotate(90deg)', fontSize: 20 }} />
      ) : (
        <SwitchRightRoundedIcon sx={{ transform: 'rotate(90deg)', fontSize: 20 }} />
      )}
    </div>
  );
};

const normalizeColumns = (elements) => {
  const columns = [];
  React.Children.forEach(elements, (element) => {
    if (React.isValidElement(element) && element.key) {
      const column = { ...element.props, key: element.key };
      columns.push(column);
    }
  });
  return columns;
};

// headerHeight rowHeight footerHeight
const HEIGHT_MAP = {
  small: [36, 33, 0],
  middle: [48, 44, 0],
  large: [56, 53, 0],
};

const ProTable = forwardRef(
  (
    {
      rowKey,
      columns,
      data,
      rowSelection,
      fixed = true,
      sortBy = {},
      children,
      onColumnSort = () => {},
      scroll,
      size = 'middle',
      components = {},
      ...rest
    },
    ref
  ) => {
    const { x, y } = scroll || { x: undefined, y: undefined };
    const [headerHeight, rowHeight, footerHeight] = HEIGHT_MAP[size];

    const cols = React.useMemo(() => {
      let normalizeTitleCol = [];
      if (columns) {
        normalizeTitleCol = columns.map(({ title, ...restColumnProps }) => ({
          title: React.isValidElement(title) ? ReactDOMServer.renderToString(title) : title,
          ...restColumnProps,
        }));
      } else {
        normalizeTitleCol = normalizeColumns(children);
      }

      if (rowSelection) {
        const {
          selectedRowKeys = [],
          type = 'checkbox',
          onChange = () => {},
          selectedRows = [],
          rowSelectfrozen = Column.FrozenDirection.LEFT,
        } = rowSelection;
        const selectionColumn = {
          width: 60,
          flexShrink: 0,
          resizable: false,
          frozen: rowSelectfrozen,
          key: '__selection__',
          rowKey,
          selectedRowKeys,
          selectedRows,
          headerRenderer: SelectionHeader,
          cellRenderer: SelectionCell,
          onChange,
          getCheckboxProps: rowSelection?.getCheckboxProps,
          rowRenderer: rowSelection?.rowRenderer,
          type,
          data,
        };
        return [selectionColumn, ...normalizeTitleCol];
      }
      return normalizeTitleCol;
    }, [columns, rowSelection, children, rowKey, data]);
    return (
      <AutoResizer width={x} height={y}>
        {({ width, height }) => (
          <Table
            ref={ref}
            width={width}
            rowKey={rowKey}
            data={data}
            {...(data?.length > 0 ? { maxHeight: height } : { height: 200 + headerHeight })}
            rowSelection={rowSelection}
            fixed={fixed}
            columns={cols}
            emptyRenderer={<Empty />}
            // headerHeight={headerHeight}
            // rowHeight={rowHeight}
            // footerHeight={footerHeight}
            sortBy={sortBy}
            onColumnSort={({ column, key, order }) => {
              // desc -> asc -> false
              if (sortBy && sortBy.key === key) {
                if (order === SortOrder.ASC) {
                  onColumnSort({ key, order });
                } else {
                  onColumnSort({ key, order });
                }
              } else {
                onColumnSort({ key, order: SortOrder.ASC });
              }
            }}
            components={{
              SortIndicator,
              TableCell: MuiTableCell,
              ...components,
            }}
            {...rest}
          />
        )}
      </AutoResizer>
    );
  }
);
export default ProTable;
