const baseUrl = 'rest/';
const serviceName = 'client_HomePageService';

export default (build) => ({
  // 查询首页课程列表(Public)
  getTopCourses: build.query({
    query: (searchKey) => ({
      url: `${baseUrl}services/${serviceName}/queryCourse`,
      method: 'post',
      data: { searchKey },
      isPublic: true,
    }),
  }),
  // 查询首页案例列表(Public)
  getTopCases: build.query({
    query: (searchKey) => ({
      url: `${baseUrl}services/${serviceName}/queryCase`,
      method: 'post',
      data: { searchKey },
      isPublic: true,
    }),
  }),
});
