/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

import OrganizationAddMem from './OrganizationAddMem';
import PopUp from '../PopUp';
import TableContainer from 'components/table/TableContainer';
import ProTable from 'components/table/ProTable';
import Search from '../components/Search';
import UserAvatar from '../components/UserAvatar';
import { enterpriseUserTypCN } from 'common/constans';
import api from 'services/api';

const OrganizationMembers = ({ oId }) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { id } = useParams();
  const dataRef = useRef(oId);
  const { data = { total: 0, data: [] }, isLoading } = api.useOrganizationMemberListQuery(
    {
      enterpriseId: id,
      organizationId: oId,
      deactivate: false,
      search,
      limit: pageSize,
      offset: page * pageSize,
    },
    {
      skip: !id || !oId,
    }
  );

  const handleSearch = ({ search = '' }) => {
    setSearch(search.trim());
    setPage(0);
  };

  useEffect(() => {
    dataRef.current = oId;
  }, [oId]);

  // 分页
  const handlePerPageChange = (event) => {
    setPageSize(event.target.value);
  };
  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const columns = [
    {
      dataKey: 'user',
      key: 'user',
      title: '用户',
      width: 200,
      frozen: 'left',
      dataGetter: ({ rowData, rowIndex }) => (
        <Box width={1} py={1} display="flex" alignItems="center">
          <Box mr={1.5}>
            {rowData?.avatar ? (
              <Avatar
                sx={{
                  width: '40px',
                  height: '40px',
                  mt: 0.5,
                }}
              >
                <img
                  src={rowData?.avatar}
                  alt=""
                  loading="lazy"
                  style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
              </Avatar>
            ) : (
              <UserAvatar index={rowIndex % 10} name={rowData.nickName} />
            )}
          </Box>
          <Box>
            <Typography fontSize={14} fontWeight={400}>
              {rowData.nickName}
            </Typography>
            <Typography mt={0.5} fontSize={12} variant="body2" sx={{ opacity: 0.7 }}>
              {enterpriseUserTypCN[rowData?.identity]}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      dataKey: 'email',
      key: 'email',
      title: '邮箱',
      width: 250,
    },
    {
      width: 120,
      key: '_',
      title: '操作',
      frozen: 'right',
      dataGetter: ({ rowData }) => (
        <PopUp
          organizationId={dataRef.current}
          type="delete"
          title="移除成员"
          text="是否要从组织移除该成员"
          arr={rowData}
        />
      ),
    },
  ];

  return (
    <Box px={2} height={1} display="flex" flexDirection="column">
      <Typography fontSize={20} fontWeight={500}>
        组织成员
      </Typography>
      <Toolbar>
        <Search onSubmit={handleSearch} />
        <Box flexGrow={1} />
        <OrganizationAddMem title="添加新成员" oId={oId} />
      </Toolbar>
      <Box flex={1}>
        <TableContainer isLoading={isLoading}>
          <ProTable rowKey="id" columns={columns} data={data?.data} />
          <Box justifyContent="flex-end" display="flex" p={2}>
            <TablePagination
              component="div"
              count={data?.total}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          </Box>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default OrganizationMembers;
