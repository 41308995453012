const baseUrl = 'rest/';
const serviceName = 'client_LectureService';
export const types = ['Lecture'];

export default (builder) => ({
  // 列表
  getLectureList: builder.query({
    query: ({ data, signal }) => ({
      url: `${baseUrl}services/${serviceName}/search`,
      method: 'post',
      data: { request: data },
      signal,
      isPublic: true,
    }),
  }),

  // 获取详情
  getLectureDetail: builder.query({
    query: (id) => ({
      url: `${baseUrl}services/${serviceName}/query`,
      method: 'post',
      data: { id },
      isPublic: true,
    }),
  }),
});
