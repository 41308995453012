import { createSlice } from '@reduxjs/toolkit';

import config from 'services/jmix/config';

const tokenStorageKey = config.tokenStorageKey;

const initialState = {
  initialized: false,
  authorized: false,
  token: config.storage.getItem(tokenStorageKey),
  user: null,
  isNewUser: false,
  showOnboardingModal: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAccount: (state, action) => {
      state.user = action.payload;
      state.initialized = true;
      state.authorized = true;
      state.authModal = null;
    },
    resetAccount: (state) => {
      state.token = null;
      state.authorized = false;
      state.user = null;
    },
    setCredentials: (state, { payload: { accessToken } }) => {
      localStorage.setItem(tokenStorageKey, accessToken);
      state.token = accessToken;
    },
    clearCredentials: (state) => {
      localStorage.removeItem(tokenStorageKey);
      state.token = null;
      state.authorized = false;
      state.user = null;
    },
  },
});

export const authActions = {
  ...authSlice.actions,
};

export default authSlice.reducer;
