import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { useTheme } from '@mui/material/styles';
import Text from 'components/Text';

import ResponsiveDialog from 'components/dialog/ResponsiveDialog';
import ClosableDialogTitle from 'components/dialog/ClosableDialogTitle';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgetPassword from './ForgetPassword';

export default function AuthModal() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [searchparams, setSearchParams] = useSearchParams();
  const authModal = searchparams.get('authMode');

  const handleClose = () => {
    searchparams?.delete('authMode');
    setSearchParams(searchparams);
  };

  const [content, title] = useMemo(() => {
    switch (authModal) {
      case 'login':
        return [<SignIn />, <Text tid="common.welcome" />];
      case 'reset':
        return [<ForgetPassword />, <Text tid="common.forgetPassword" />];
      case 'signup':
        return [<SignUp />, <Text tid="common.signup" />];
      default:
        return [null, null];
    }
  }, [authModal]);

  if (!authModal) {
    return null;
  }

  return (
    <ResponsiveDialog fullScreen={fullScreen} maxWidth="xs" open onClose={handleClose}>
      <ClosableDialogTitle onClose={handleClose}>{title}</ClosableDialogTitle>
      <Divider />
      <DialogContent sx={{ px: { xs: 4, sm: 8 }, py: { xs: 2, sm: 3 } }}>
        {authModal === 'login' && (
          <Typography sx={{ mt: 3 }} align="center" variant="h5" gutterBottom component="div">
            解锁完整用户功能
          </Typography>
        )}
        {content}
      </DialogContent>
    </ResponsiveDialog>
  );
}
