import { createApi } from '@reduxjs/toolkit/query/react';

import { authActions } from 'store/authSlice';
import common, { types as commonTypes } from './common';
import course, { types as courseTypes } from './course';
import cases, { types as caseTypes } from './case';
import auth, { types as authTypes } from './auth';
import home from './home';
import competition from './competition';
import lecture from './lecture';
import enterprise from './enterprise';
import organizationChart from './organizationChart';
import administration from './administration';

import axiosBaseQuery from './jmix/axiosBaseQuery';
import config from './jmix/config';

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseURL: config?.apiUrl, onTokenExpiry: authActions.clearCredentials }),
  tagTypes: [...commonTypes, ...courseTypes, ...caseTypes, ...authTypes],
  endpoints: (build) => ({
    login: build.mutation({
      query: ({ username, password }) => ({
        url: `oauth/token`,
        method: 'POST',
        auth: {
          username: config.restClientId,
          password: config.restClientSecret,
        },
        data: `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
        transformResponse: [
          (res) => {
            const data = JSON.parse(res);
            return { accessToken: data?.access_token };
          },
        ],
      }),
    }),
    revokeToken: build.mutation({
      query: (token) => ({
        url: `oauth/revoke`,
        method: 'POST',
        auth: {
          username: config.restClientId,
          password: config.restClientSecret,
        },
        data: `token=${encodeURIComponent(token)}`,
      }),
    }),
    signUp: build.mutation({
      query: (userData) => ({
        url: `rest/login/register`,
        method: 'POST',
        data: userData,
      }),
    }),
    resetPassword: build.mutation({
      query: (params) => ({
        url: `users`,
        params,
      }),
    }),
    ...common(build),
    ...course(build),
    ...cases(build),
    ...home(build),
    ...auth(build),
    ...competition(build),
    ...lecture(build),
    ...enterprise(build),
    ...organizationChart(build),
    ...administration(build),
  }),
});

export default api;
