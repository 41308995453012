import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch() {
    // 你同样可以将错误日志上报给服务器
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4">500: Unknown error</Typography>
            <Typography variant="subtitle2">
              Sorry, something went wrong, please refresh the webpage later, if it continues to be abnormal, please
              contact the relevant technical staff. &nbsp;
              <Button
                variant="text"
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                Reload Page
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Back Home
              </Button>
            </Typography>
          </Box>
        </Box>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
