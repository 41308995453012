const competition = {
  name: '竞赛',
  description: '在解决各行业智能决策问题中挑战自我，在与优秀人才的较量中提升分析与建模能力。',
  fields: {
    title: '标题',
    subject: '简介',
    status: '状态',
    sponsoredBy: '举办方',
    startDate: '开始时间',
    endDate: '结束时间',
    prize: '奖金',
    deadline: '截止时间',
  },
  status: {
    ongoing: '进行中',
    ended: '已结束',
  },
};

export default competition;
