import React from 'react';
import Link from '@mui/material/Link';

const ExternalLink = ({ children, ...restProps }) => (
  <Link external color="inherit" underline="hover" target="_blank" rel="noopener noreferrer" {...restProps}>
    {children}
  </Link>
);

export default ExternalLink;
